import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
// import { ReCaptcha } from 'react-recaptcha-v3';

// import config from '../config';
import rivalitasApi from '../rivalitasApi';

import ApplicationContext from '../ApplicationContext';
// import RecaptchaNotes from '../components/common/RecaptchaNote';
import Select from '../components/common/Select';
import TermsAndConditionsNote from '../components/common/TermsAndConditionsNote';

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

import TextField from '@material-ui/core/TextField';

import { green } from '@material-ui/core/colors';

const useSnackStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const numberToString = n => !n || isNaN(n) ? '' : n.toString();
const stringToPositiveNumber = s => {
  if (!s)
  {
    return null;
  }

  const parsed = parseInt(s);
  return !isNaN(parsed) && parsed >=0 ? parsed : null;
};

const useStyles = makeStyles(contactUsStyle);

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

function SnackbarContentWrapper(props) {
  const classes = useSnackStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'success']).isRequired,
};

const bgStyle = {
  height: '100%',
  width: '100%',
  objectFit: 'cover'
};

function locationToId(l) {
  let result = l.country;
  if (l.city) {
    result += `-${l.city}`;
  }
  if (l.block) {
    result += `-${l.block}`;
  }

  return result;
}

function locationToLabel(l) {
  let result = '';

  if (l.block) {
    result += l.block;
  }
  if (l.city) {
    result += result ? `, ${l.city}` : l.city;
  }
  result += result ? '' : l.country;

  return result;
}

const WantedRealEstate = ({ data, onChange }) => {

  const appContext = useContext(ApplicationContext);
  const realEstateTypeSuggestions = appContext.registers.realEstateTypes.map(t => ({
    label: t,
    value: t
  }));

  const offerTypeSuggestions = appContext.registers.offerTypes.map(t => ({
    label: t,
    value: t
  }));

  async function searchLocations(input) {
    const result = await rivalitasApi.searchLocations(input);
    if (!result || result.length <= 0) {
      return [];
    }
    return result.map(x => ({
      value: locationToId(x),
      label: locationToLabel(x)
    }));
  }

  const dataCopy = {...data};
  const selectedRealEstates = [];
  const selectedOfferTypes = [];
  
  data.realEstateTypes.forEach(t => {
    const match = realEstateTypeSuggestions.find(x => x.value === t);
    if (match) {
      selectedRealEstates.push(match);
    }
  });
  data.offerTypes.forEach(t => {
    const match = offerTypeSuggestions.find(x => x.value === t);
    if (match) {
      selectedOfferTypes.push(match);
    }
  });

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Select
          label="Tipovi nekretnina"
          placeholder="Tipovi nekretnina"
          suggestions={realEstateTypeSuggestions}
          value={selectedRealEstates}
          onChange={x => {
            dataCopy.realEstateTypes = x ? x.map(v => v.value) : [];
            onChange(dataCopy);
          }}
          isMulti
        />
        <br />
      </GridItem>
      <GridItem xs={12}>
        <Select
          label="Vrste ponude"
          placeholder="Vrste ponude"
          suggestions={offerTypeSuggestions}
          value={selectedOfferTypes}
          onChange={x => {
            dataCopy.offerTypes = x ? x.map(v => v.value) : [];
            onChange(dataCopy);
          }}
          isMulti
        />
        <br />
      </GridItem>
      <GridItem xs={12}>
        <Select
          async
          label="Lokacije"
          placeholder="Lokacije"
          value={data.locations}
          onChange={x => {
            dataCopy.locations = x ? [...x] : [];
            onChange(dataCopy);
          }}
          loadOptions={searchLocations}
          noResultsLabel="Upiši lokaciju..."
          isMulti
          creatable
        />
      </GridItem>
      <GridItem xs={12} sm={6}>
        <TextField
          label="Sobnost od"
          value={numberToString(dataCopy.roomNumberFrom)}
          onChange={e => {
            dataCopy.roomNumberFrom = stringToPositiveNumber(e.target.value);
            onChange(dataCopy);
          }}
          margin="normal"
          fullWidth
          type="number"
          min="0"
        />
      </GridItem>
      <GridItem xs={12} sm={6}>
        <TextField
          label="Sobnost do"
          value={numberToString(dataCopy.roomNumberTo)}
          onChange={e => {
            dataCopy.roomNumberTo = stringToPositiveNumber(e.target.value);
            onChange(dataCopy);
          }}
          margin="normal"
          fullWidth
          type="number"
          min="0"
        />
      </GridItem>
      <GridItem xs={12} sm={6}>
        <TextField
          label="Površina od"
          value={numberToString(dataCopy.areaFrom)}
          onChange={e => {
            dataCopy.areaFrom = stringToPositiveNumber(e.target.value);
            onChange(dataCopy);
          }}
          margin="normal"
          fullWidth
          type="number"
          min="0"
        />
      </GridItem>
      <GridItem xs={12} sm={6}>
        <TextField
          label="Površina do"
          value={numberToString(dataCopy.areaTo)}
          onChange={e => {
            dataCopy.areaTo = stringToPositiveNumber(e.target.value);
            onChange(dataCopy);
          }}
          margin="normal"
          fullWidth
          type="number"
          min="0"
        />
      </GridItem>
      <GridItem xs={12} sm={6}>
        <TextField
          label="Cijena od"
          value={numberToString(dataCopy.priceFrom)}
          onChange={e => {
            dataCopy.priceFrom = stringToPositiveNumber(e.target.value);
            onChange(dataCopy);
          }}
          margin="normal"
          fullWidth
          type="number"
          min="0"
        />
      </GridItem>
      <GridItem xs={12} sm={6}>
        <TextField
          label="Cijena do"
          value={numberToString(dataCopy.priceTo)}
          onChange={e => {
            dataCopy.priceTo = stringToPositiveNumber(e.target.value);
            onChange(dataCopy);
          }}
          margin="normal"
          fullWidth
          type="number"
          min="0"
        />
      </GridItem>
    </GridContainer>
  );
};

const DefaultWantedRealEstate = {
  realEstateTypes: [],
  offerTypes: [],
  locations: [],
  roomNumberFrom: null,
  roomNumberTo: null,
  areaFrom: null,
  areaTo: null,
  priceFrom: null,
  priceTo: null
};

function isWantedRealEstateValid(w) {
  if (!w) {
    return false;
  }
  return (w.realEstateTypes && w.realEstateTypes.length > 0) ||
    (w.offerTypes && w.offerTypes.length > 0) ||
    (w.locations && w.locations.length > 0) ||
    w.roomNumberFrom !== null ||
    w.roomNumberTo !== null ||
    w.areaFrom !== null ||
    w.areaTo !== null ||
    w.priceFrom !== null ||
    w.priceTo !== null;
}

function parseLocationObject(l) {
  if (!l || !l.value) {
    return null;
  }

  return l.value.split('-').pop();
}

function SubmitRealEstateQuery() {
  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  // });
  const classes = useStyles();

  const [recaptchaToken/*, setRecaptchaToken*/] = useState(null);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [wantedRealEstate, setWantedRealEstate] = useState({...DefaultWantedRealEstate});

  const [showContactConfirmation, setShowContactConfirmation] = React.useState(false);
  const [contactSendSuccess, setContactSendSuccess] = useState(false);

  const handleNameChange = e => setName(e.target.value);
  const handlePhoneChange = e => setPhone(e.target.value);
  const handleEmailChange = e => setEmail(e.target.value);

  const handleDataSaved = () => {
    setName('');
    setPhone('');
    setEmail('');
    setWantedRealEstate({...DefaultWantedRealEstate});
  };

  async function sendContactMessage() {
    handleDataSaved();

    const apiResult = await rivalitasApi.saveWantedRealEstate({
      name,
      phone,
      email,
      wantedRealEstate: {
        ...wantedRealEstate,
        locations: wantedRealEstate.locations.map(l => parseLocationObject(l)),
      },
      recaptchaToken
    });

    setContactSendSuccess(apiResult && apiResult.success);
    setShowContactConfirmation(true);
  };

  // const verifyCallback = (recaptchaToken) => {
  //   setRecaptchaToken(recaptchaToken);
  // }

  const handleCloseContactConfirmation = () => {
    setShowContactConfirmation(false);
  };

  return (
    <div>
      {/* <ReCaptcha
        sitekey={config.recaptchaKey}
        action="submitRealEstate"
        verifyCallback={verifyCallback}
      /> */}

      <Helmet>
        <title>Rivalitas Nekretnine - Tražim nekretninu</title>
        <meta name="description" content="Tražite nekretninu? Javite nam se." />
        <meta name="keywords" content="proadja nekretnina" />
      </Helmet>

      <div className={classes.bigMap}>
        <img src="/assets/img/submitRe.jpg" alt="Tražim nekretninu" style={bgStyle} />
      </div>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <br />
            <h2 className={classes.title}>Tražite nekretninu?</h2>
            <GridContainer>
              <GridItem md={12} sm={12} xs={12}>
                <h3>Pošaljite nam detalje i javiti ćemo Vam se u najkraćem roku</h3>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Vaše ime i prezime *"
                  fullWidth
                  value={name}
                  onChange={handleNameChange}
                  error={!name}
                  helperText={!name ? 'Molimo unesite Vaše ime i prezime' : null}
                />
                <TextField
                  margin="dense"
                  label="Telefon / Mob"
                  fullWidth
                  value={phone}
                  onChange={handlePhoneChange}
                  // error={!contactData}
                  // helperText={!contactData ? 'Molimo unesite Vaš kontakt kako bi Vas mogli kontaktirati' : null}
                />
                <TextField
                  margin="dense"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={handleEmailChange}
                  error={!phone && !email}
                  helperText={(!phone && !email) ? 'Molimo unesite barem jedan kontakt kako bi Vas mogli kontaktirati' : null}
                />
                <br />
                <br />
                <Divider />
                <h3>Detalji nekretnine</h3>
                <Divider />
                <br />
                <WantedRealEstate data={wantedRealEstate} onChange={w => setWantedRealEstate(w)} />

                {!isWantedRealEstateValid(wantedRealEstate) &&

                  <p
                    className="MuiFormHelperText-root Mui-error MuiFormHelperText-marginDense"
                  >
                    Molimo unesite barem jedan paremetar za traženu nekretninue
                  </p>
                }

                <Button
                  onClick={sendContactMessage}
                  color="primary"
                  disabled={!name || !(phone || email) || !isWantedRealEstateValid(wantedRealEstate)}
                  variant="contained"
                >
                  Spremi
                </Button>
                <TermsAndConditionsNote />
                {/* <RecaptchaNotes /> */}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <br />
      <br />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showContactConfirmation}
        autoHideDuration={6000}
        onClose={handleCloseContactConfirmation}
      >
        <SnackbarContentWrapper
          variant={contactSendSuccess ? 'success' : 'error'}
          className={classes.margin}
          message={contactSendSuccess ? 'Vaši podaci su spremljeni' : 'Greška: molimo pokušajte ponovo'}
          onClose={handleCloseContactConfirmation}
        />

      </Snackbar>
    </div>
  );
}

export default SubmitRealEstateQuery;
