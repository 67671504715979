import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../views/Home';
import RealEstate from '../views/RealEstate';
import Search from '../views/Search';
import Sve from '../views/search/Sve';
import NajamStanovi from '../views/search/NajamStanovi';
import NajamKuce from '../views/search/NajamKuce';
import NajamZagreb from '../views/search/NajamZagreb';
import NajamStanoviZagreb from '../views/search/NajamStanoviZagreb';
import NajamKuceZagreb from '../views/search/NajamKuceZagreb';
import StanoviZagrebCentar from '../views/search/StanoviZagrebCentar';
import StanoviZagrebJarun from '../views/search/StanoviZagrebJarun';
import StanoviZagrebCrnomerec from '../views/search/StanoviZagrebCrnomerec';
import StanoviZagrebCvjetnoNaselje from '../views/search/StanoviZagrebCvjetnoNaselje';
import StanoviZagrebVrbik from '../views/search/StanoviZagrebVrbik';
import StanoviZagrebMaksimir from '../views/search/StanoviZagrebMaksimir';
import StanoviZagrebDubrava from '../views/search/StanoviZagrebDubrava';
import StanoviZagrebTresnjevka from '../views/search/StanoviZagrebTresnjevka';
import StanoviZagreb from '../views/search/StanoviZagreb';
import JednosobniStanoviZagreb from '../views/search/JednosobniStanoviZagreb';
import DvosobniStanoviZagreb from '../views/search/DvosobniStanoviZagreb';
import TrosobniStanoviZagreb from '../views/search/TrosobniStanoviZagreb';
import JednosobniStanovi from '../views/search/JednosobniStanovi';
import DvosobniStanovi from '../views/search/DvosobniStanovi';
import TrosobniStanovi from '../views/search/TrosobniStanovi';
import Novogradnje from '../views/search/Novogradnje';
import Kuce from '../views/search/Kuce';
import Stanovi from '../views/search/Stanovi';
import Prodaja from '../views/search/Prodaja';
import Najam from '../views/search/Najam';
import NotFound from '../views/NotFound';
import AboutUs from '../views/AboutUs';
import Buyers from '../views/Buyers';
import Sellers from '../views/Sellers';
import ECertificates from '../views/ECertificates';
import Contact from '../views/Contact';
import SubmitRealEstate from '../views/SubmitRealEstate';
import SubmitRealEstateQuery from '../views/SubmitRealEstateQuery';
import TermsAndConditions from '../views/TermsAndConditions';

var routes = (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/Pretraga/Sve/:page?" component={Sve} />
      <Route exact path="/Pretraga/Napredna/:page?" component={Search} />
      <Route exact path="/Pretraga/Najam-Stanovi/:page?" component={NajamStanovi} />
      <Route exact path="/Pretraga/Najam-Kuce/:page?" component={NajamKuce} />
      <Route exact path="/Pretraga/Najam-Zagreb/:page?" component={NajamZagreb} />
      <Route exact path="/Pretraga/Najam-Stanovi-Zagreb/:page?" component={NajamStanoviZagreb} />
      <Route exact path="/Pretraga/Najam-Kuce-Zagreb/:page?" component={NajamKuceZagreb} />
      <Route exact path="/Pretraga/Stanovi-Zagreb-Centar/:page?" component={StanoviZagrebCentar} />
      <Route exact path="/Pretraga/Stanovi-Zagreb-Jarun/:page?" component={StanoviZagrebJarun} />
      <Route exact path="/Pretraga/Stanovi-Zagreb-Crnomerec/:page?" component={StanoviZagrebCrnomerec} />
      <Route exact path="/Pretraga/Stanovi-Zagreb-Vrbik/:page?" component={StanoviZagrebVrbik} />
      <Route exact path="/Pretraga/Stanovi-Zagreb-CvjetnoNaselje/:page?" component={StanoviZagrebCvjetnoNaselje} />
      <Route exact path="/Pretraga/Stanovi-Zagreb-Maksimir/:page?" component={StanoviZagrebMaksimir} />
      <Route exact path="/Pretraga/Stanovi-Zagreb-Dubrava/:page?" component={StanoviZagrebDubrava} />
      <Route exact path="/Pretraga/Stanovi-Zagreb-Tresnjevka/:page?" component={StanoviZagrebTresnjevka} />
      <Route exact path="/Pretraga/Stanovi-Zagreb/:page?" component={StanoviZagreb} />
      <Route exact path="/Pretraga/Jednosobni-Stanovi-Zagreb/:page?" component={JednosobniStanoviZagreb} />
      <Route exact path="/Pretraga/Dvosobni-Stanovi-Zagreb/:page?" component={DvosobniStanoviZagreb} />
      <Route exact path="/Pretraga/Trosobni-Stanovi-Zagreb/:page?" component={TrosobniStanoviZagreb} />
      <Route exact path="/Pretraga/Jednosobni-Stanovi/:page?" component={JednosobniStanovi} />
      <Route exact path="/Pretraga/Dvosobni-Stanovi/:page?" component={DvosobniStanovi} />
      <Route exact path="/Pretraga/Trosobni-Stanovi/:page?" component={TrosobniStanovi} />
      <Route exact path="/Pretraga/Novogradnje/:page?" component={Novogradnje} />
      <Route exact path="/Pretraga/Kuce/:page?" component={Kuce} />
      <Route exact path="/Pretraga/Stanovi/:page?" component={Stanovi} />
      <Route exact path="/Pretraga/Prodaja/:page?" component={Prodaja} />
      <Route exact path="/Pretraga/Najam/:page?" component={Najam} />
      <Route exact path="/Nekretnine/:title/:id" component={RealEstate} />
      <Route exact path="/ONama" component={AboutUs} />
      <Route exact path="/Kupci" component={Buyers} />
      <Route exact path="/Prodavatelji" component={Sellers} />
      <Route exact path="/NijePronadjeno" component={NotFound} />
      <Route exact path="/EnergetskiCertifikati" component={ECertificates} />
      <Route exact path="/Kontakt" component={Contact} />
      <Route exact path="/ProdajNekretninu" component={SubmitRealEstate} />
      <Route exact path="/UvjetiKoristenja" component={TermsAndConditions} />
      <Route exact path="/TrazimNekretninu" component={SubmitRealEstateQuery} />
      <Route path="/" component={NotFound} />
    </Switch>
  );
  
  export default routes;