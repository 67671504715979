import React from 'react';

import SearchResult from '../implementation/SearchResult';


const searchParams = {
  publicId: null,
  location: 'Zagreb',
  realEstateType: 'Stanovi',
  offerType: null,
  price: {
    from: null,
    to: null
  },
  area: {
    from: null,
    to: null
  },
  roomNumber: {
    from: null,
    to: null
  },
  floor: {
    from: null,
    to: null
  },
  newbuilding: false,
  hasLift: false
};

function StanoviZagreb() {

  const title = 'Rivalitas Nekretnine - Prodaja stanova Zagreb - Stanovi Zagreb';
  const description = 'Prodaja stanova na području Zagreba - odaberite jednosobni, dvosobni, trosobni ili višesobni stan na prodaju. Stanovi Zagreb.';
  const keywords = 'agencija, nekretnine, stanovi, kuće, novogradnje, najam, prodaja';

  return (
    <SearchResult queryParams={searchParams} title={title} description={description} keywords={keywords} />
  );
}

export default StanoviZagreb;
