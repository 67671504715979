import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contactStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/contactStyle.js";

const useStyles = makeStyles(aboutUsStyle);
const useContactStyles = makeStyles(contactStyle);

function ECertificates() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const contactClasses = useContactStyles();

  return (
    <div>
      <Helmet>
        <title>Rivalitas Nekretnine - Energetski certifikati</title>
        <meta name="description" content="Izrada energetskih certifikata za nekretnine po povoljnim cijenama" />
        <meta name="keywords" content="Energetski certifikati" />
      </Helmet>

      <Parallax image="/assets/img/ecertificates.jpg" filter="dark" small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Energetski certifikati</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={contactClasses.aboutUs}>
            <GridContainer>
              <GridItem
                md={8}
                sm={8}
                className={classNames(contactClasses.mrAuto, contactClasses.mlAuto)}
              >
                <p>
                  Od 1. siječnja 2014. po odredbama Zakona o gradnji vlasnici nekretnina i agencije za posredovanje u prometu nekretninama moraju u oglasu objavljenom u medijima za prodaju nekretnine istaknuti njen energetski razred.
                </p>
                <p>
                  Za kršenje ove odredbe određene su novčane kazne u iznosu od 5.000 kn do 10.000 kn za fizičke osobe - vlasnike nekretnina i od 15.000 kn do 30.000 kn za pravne osobe - vlasnike zgrade i ovlaštene posrednike u prometu nekretninama (Članak 171 zakona ).
                </p>

                <p>
                  <ul>
                    <li>Kada počne s prodajom nekretnine vlasnik je obvezan:</li>
                    <li>Imati energetski certifikat</li>
                    <li>Navesti energetski razred nekretnine.</li>
                    <li>Potencijalnom kupcu dati na uvid energetski certifikat i po dogovorenoj kupoprodaji predati mu isti.</li>
                    <li>Energetski certifikat može biti razreda A+, A, B,C, D, E, F, G.</li>
                  </ul>
                </p>

                <p>
                  Enegetski razred A+ dobivaju nekretnine u kojima je potrošnja energije za grijanje odnosno hlađenje najmanja (ovisno o materijalima koji su korišteni u gradnji). Energetski razred G dobivaju nekretnine koje su najmanje ekonomične, odnosno u kojima je potrošnja energije za grijanje odnosno hlađenje najveća. Između ostalog, podatak o energetskoj učinkovitosti nekretnine je važan kako bi potencijalni kupac mogao donijeti odluku o isplativosti kupovine.
                </p>

                <p>
                  Agencija <strong>Rivalitas</strong> Vam nudi izradu energetskih certifikata po povoljnim cijenama.
                </p>

                <p>
                  <Link to="/Kontakt">Kontaktirajte</Link> nas s povjerenjem.
                </p>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ECertificates;
