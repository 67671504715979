import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import queryString from 'query-string';

import { stringToPositiveNumber } from '../util';

import SearchResult from './implementation/SearchResult';


const defaultSearchParams = {
  publicId: null,
  location: null,
  realEstateType: null,
  offerType: null,
  price: {
    from: null,
    to: null
  },
  area: {
    from: null,
    to: null
  },
  roomNumber: {
    from: null,
    to: null
  },
  floor: {
    from: null,
    to: null
  },
  newbuilding: false,
  hasLift: false
};

function Search(props) {
  const [queryParams, setQueryParams] = useState(null);

  function parseQueryParams() {
    const query = queryString.parse(props.location.search);

    const searchParams = {
      ...defaultSearchParams
    };

    searchParams.area.from = stringToPositiveNumber(query.af);
    searchParams.area.to = stringToPositiveNumber(query.at);

    searchParams.price.from = stringToPositiveNumber(query.pf);
    searchParams.price.to = stringToPositiveNumber(query.pt);

    searchParams.roomNumber.from = stringToPositiveNumber(query.rf);
    searchParams.roomNumber.to = stringToPositiveNumber(query.rt);

    searchParams.floor.from = stringToPositiveNumber(query.ff);
    searchParams.floor.to = stringToPositiveNumber(query.ft);

    searchParams.newbuilding = query.nb === 'Novogradnje';
    searchParams.realEstateType = query.ret || '';
    searchParams.offerType = query.ot || '';
    searchParams.location = query.lq || '';
    searchParams.hasLift = query.lft === 'ImaLift';
    searchParams.publicId = query.pid || '';

    setQueryParams(searchParams);
  }

  useEffect(() =>{
    parseQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search]);

  const title = 'Rivalitas Nekretnine - Napredna pretraga';
  const description = 'Rezultati pretrage nekretnina, stanova, kuća, novogradnji i starogradnji';
  const keywords = 'agencija, nekretnine, stanovi, kuće, novogradnje, najam, prodaja';

  return (
    <SearchResult queryParams={queryParams} title={title} description={description} keywords={keywords} />
  );
}

export default withRouter(Search);
