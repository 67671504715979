import React from 'react';
import { Helmet } from 'react-helmet';

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contactStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/contactStyle.js";

const useStyles = makeStyles(theme => {
  return {
    terms: {
      backgroundColor: theme.palette.primary.main,
      height: '250px'
    },
    ...aboutUsStyle
  };
});

const useContactStyles = makeStyles(contactStyle);

function TermsAndConditions() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const contactClasses = useContactStyles();

  return (
    <div>
      <Helmet>
        <title>Rivalitas Nekretnine - Uvjeti korištenja</title>
        <meta name="description" content="Uvjeti korištenja." />
        <meta name="keywords" content="Uvjeti korištenja." />
      </Helmet>

      <div className={classes.terms} />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={contactClasses.aboutUs}>
            <GridContainer>
              <GridItem
                md={12}
                sm={12}
                className={classNames(contactClasses.mrAuto, contactClasses.mlAuto)}
              >
                <h3 className={classNames(contactClasses.title, contactClasses.textCenter)}>
                  Uvjeti korištenja
                </h3>
                <p>
                Poštovani korisnici web stranice <a href="/">www.rivalitas.com</a>
                </p>
                <p>
                  prema Uredbi o zaštiti osobnih podataka (GDPR EU 2016/679 EUROPSKOG PARLAMENTA I VIJEĆA od 27. travnja 2016.) dužni smo od Vas tražiti privolu o prikupljanju i korištenju Vaših osobnih podataka. Podaci koje prikupljamo i obrađujemo su slijedeći: Ime, prezime, Adresa, Broj telefona, E-mail adresa. Podaci se prikupljaju putem online obrazaca.
                </p>
                <p>
                  Podatke korisnika koje je isti ostavio putem navedenog online obrasca prikupljamo zbog lakšeg komuniciranja s korisnikom kako bi dobio relevantne informacije o našim aktivnostima. Ostavljanjem svojih podataka korisnik nam daje dozvolu da ga informiramo o ponudi nekretnina koje odgovaraju željama i parametrima korisnika, te da mu šaljemo informativne tekstove o našoj ponudi i informacije o našim aktivnostima.
                </p>
                <p>
                  U skladu s Općom uredbom o zaštiti osobnih podataka (GDPR), svi podaci o korisnicima su strogo povjerljivi i čuvani, ne dostavljaju se i ne otkrivaju trećoj strani, a dostupni su isključivo ovlaštenim osobama u skladu sa zakonskim propisima koji uređuju zaštitu osobnih podataka.
                  <br />
                  Rivalitas d.o.o provodi sve mjere kako bi maksimalno i učinkovito zaštitio privatnost svojih korisnika.
                </p>
                <p>
                  Prikupljenim podacima pristup imaju ovlaštene osobe društva. Svim ostalim osobama uvid u Vaše osobne podatke nije omogućen. Rok za čuvanje poslovne dokumentacije s Vašim osobnim podacima je neograničen do primljenog zahtjeva za brisanje iz evidencije. Po primitku zahtjeva za brisanje Vaši osobni podaci biti će izbrisani.
                </p>
                <p>
                  Korisnicima ćemo uvijek omogućiti odluku i izbor o upotrebi njihovih podataka i odluku žele li da se njihovi podaci trajno izbrišu. 
                </p>
                <p>
                  Pri korištenju naše stranice trebate osigurati da Vaši osobni podaci i Vaši kontakt podaci potrebni u prometu nekretninama budu potpuni i točni. Isto tako slanjem svojih podataka potvrđujete da ste zakonito sposobni koristiti ove stranice.
                </p>
                <p>
                  Rivalitas d.o.o ima pravo upotrijebiti dostavljenu e-mail adresu korisnika, koju korisnik dostavlja kao svoj osobni podatak, a u svrhu slanja informacija o ponudi nekretnina, statusu oglašenih nekretnina i ostalim informacijama vezanim za nekretnine iz ponude. 
                  <br />
                  Rivalitas d.o.o provodi sve mjere kako bi maksimalno i učinkovito zaštitio privatnost svojih korisnika.
                </p>
                <p>
                  Uvid o načinu čuvanja, korištenja i distribucije te zahtjev za brisanjem Vaših osobnih podataka možete zatražiti od Rivalitas d.o.o., a rok za dostavu traženoga je 15 radnih dana od dana podnošenja zahtjeva. Zahtjev možete uputiti u pisanom obliku na sljedeću adresu:
                  <br />
                  <br />
                  Rivalitas d.o.o.
                  <br />
                  Grahorova 11
                  <br />
                  10000 Zagreb
                </p>

                <u>Sadržaj stranica</u>

                <br />
                <p>
                  U slučaju kada informacije o oglašenim nekretninama dolaze od treće strane (vlasnici ili drugi oglašivači), Rivalitas d.o.o ne odgovara za sadržaj predanih oglasa, nego treća strana  odgovara za točnost oglasa, kvalitetu fotografija i svega drugoga što se odnosi na predmetni oglas.
                </p>
                <p>
                  Mrežne stranice mogu sadržavati poveznice (linkove) prema drugim stranicama. Ako ste preusmjereni na drugu mrežnu lokaciju, za Vas vrijede  pravila i regulativa mrežne stranice na kojoj se nalazite. Upoznati ste s činjenicom da Rivalitas d.o.o. nije autor te mrežne stranice, ne snosi odgovornost za sadržaj koji je tamo objavljen i ne regulira objavljeni sadržaj na mrežnoj stranici na koju ste preusmjereni.
                </p>

                <u>Zaštita autorskih prava i drugih prava intelektualnog vlasništva</u>

                <p>
                  Svi materijali (tekstovi, fotografije, crteži, slike, video i audio materijali), zaštićeni žigovi i logo tipovi, te svi drugi sadržaji na mrežnoj stranici zaštićeni su autorskim pravima i srodnim pravima intelektualnog vlasništva, prema Zakonu o autorskom pravu i srodnim pravima. Svi ti sadržaji su isključivo informativne prirode. Korisnicima ove web stranice nije dopušteno reproduciranje, kopiranje, distribuiranje, preinaka, prenošenje, prodaja, objavljivanje ili revidiranje sadržaja ovih stranica bez pismenog dopuštenja tvrtke Rivalitas d.o.o.
                </p>

                <u>Isključenje iz uporabe</u>

                <p>
                  Rivalitas d.o.o. ima pravo pravo isključiti od uporabe ovih stranica korisnike koji se na bilo koji način ne pridržavaju ovih uvjeta, krše ih ili na bilo koji drugi način usporavaju ili ometaju rad stranica.
                  <br />
                  Rivalitas d.o.o. također ima pravo poduzeti odgovarajuće pravne mjere protiv takvih korisnika, te zahtijevati naknadu za nastalu štetu ili gubitak. 
                  <br />
                  Rivalitas d.o.o. ima pravo  pravo isključiti iz daljnje uporabe ovih mrežnih stranica korisnike koji zloupotrebljavaju podatke objavljene na mrežnoj stranici, ili na bilo koji drugi način neovlašteno koriste navedenu mrežnu  stranicu.
                </p>

                <u>Odricanje od jamstva</u>

                <p>
                  Rivalitas d.o.o ne jamči:

                  <ul>
                    <li>točnost, potpunost i istinitost oglasa ili teksta u njima, ako su tako oglašeni na zahtjev oglašivača (vlasnika nekretina)</li>
                    <li>ispravnost, potpunost i točnost osobnih podataka oglašivača, koje je isti proslijedio i koristio  u poslovanju</li>
                    <li>da na stranicama neće biti pogrešaka i/ili da će funkcionirati bez prekida u svakom trenutku.</li>
                  </ul>
                </p>

                <u>Odricanje od odgovornosti</u>

                <p>
                  Rivalitas d.o.o ne preuzima odgovornost:

                  <ul>
                    <li>za bilo kakvu štetu, gubitak ili ozljedu koji nastaju izravno ili posredno zbog pristupa i korištenja ovih stranica ili stranica na koje vode linkovi sa Rivalitas.d.o.o. stranica</li>
                    <li>zbog informacija na ovim stranicama, kao ni zbog postupaka koji su poduzeti ili nisu poduzeti u vezi s informacijama na ovim stranicama, kao ni zbog Vaše uporabe (ili nemogućnosti da rabite) bilo koje od ovih stranica</li>
                    <li>zbog bilo kojeg sadržaja prikazanog na stranicama treće strane na koje Vam se može dopustiti povremen pristup. Bilo koji takav pristup ne može se niti se neće tumačiti kao veza s Rivalitas d.o.o. , niti kao podrška takvim stranicama od strane Rivalitas d.o.o.</li>
                  </ul>
                </p>

                <u>Politika kolačića</u>

                <p>
                  Ova mrežna stranica ne koristi kolačiće osim kolačića trećih strana i to: Google Analytics - servis za mjerenje posjećenosti i Google Maps – usluga online karata.
                  <br />
                  Više informacija o politici kolačića možete pročitati <a href="https://hr.wikipedia.org/wiki/HTTP_kola%C4%8Di%C4%87i" target="_blank" rel="noopener noreferrer">ovdje</a>
                </p>

                <p>
                  Ako se ne slažete sa bilo kojim dijelom ove stranice, ili bilo kojom odredbom ovih uvjeta, molimo Vas da ne koristite mrežnu stranicu rivalitas.hr. te bilo koje druge materijale proizašle iz korištenja mrežne stranice. Rivalitas d.o.o. zadržava pravo promijeniti sadržaj ovih Uvjeta u bilo kojem trenutku, te ukinuti pristup ovoj mrežnoj stranici bez prethodne obavijesti.
                </p>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default TermsAndConditions;
