import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  fontSize: '0.8em'
};
const withMarginStyles = {
  marginLeft: '15px',
  fontSize: '0.8em'
};

function TermsAndConditionsNote({withMargin}) {
  return (
    <div style={withMargin ? withMarginStyles : styles}>
      Slanjem Vaše poruke pristajete na naše <Link to="/UvjetiKoristenja">Uvjete korištenja</Link>
    </div>
  );
}

export default TermsAndConditionsNote;
