import React from "react";
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';

import rivalitasApi from '../../rivalitasApi';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: '2px 4px',
    margin: '2px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  progress: {
    padding: theme.spacing(1),
  },
}));

function SearchBox({ history }) {
  const classes = useStyles();

  const [fastSearch, setFastSerach] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const handleFastSearchChange = e => setFastSerach(e.target.value);

  function handleEnter(ev) {
    if (ev.key === 'Enter') {
      executeQuery();
      ev.preventDefault();
    }
  }

  async function executeQuery() {
    if (!fastSearch) {
      return;
    }

    setLoading(true);
    const r = await rivalitasApi.searchRealEstateByPublicId(fastSearch);

    let redirect = null;

    if (!!r) {
      redirect = `/Nekretnine/${r.urlFriendlyHeadline}/${r.publicId}`;
    } else {
      redirect = `/Pretraga/Napredna?lq=${encodeURI(fastSearch)}`;
    }

    setLoading(false);
    setFastSerach('');

    if (redirect) {
      history.push(redirect);
    }
  }

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Lokacija ili br. nekretnine"
        inputProps={{ 'aria-label': 'Lokacija ili br. nekretnine' }}
        value={fastSearch}
        onChange={handleFastSearchChange}
        disabled={loading}
        onKeyPress={handleEnter}
      />
      <Divider className={classes.divider} orientation="vertical" />
      {loading
      ? <CircularProgress className={classes.progress} />
      : (
          <React.Fragment>
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
              onClick={executeQuery}
              disabled={loading}
            >
              <SearchIcon />
            </IconButton>
          </React.Fragment>
        )
    }
    </Paper>
  );
}

export default withRouter(SearchBox);
