/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

const selectedStyle = {
  borderBottom: '1px solid #3ca8df'
}

function getRouteFor(props, link, label, classes) {
  if (props.location.pathname === link) {
    return <ListItem className={classes.listItem + ' ' + classes.navLinkActive}><Link to={link} className={classes.dropdownLink} style={selectedStyle}>{label}</Link></ListItem>;
  }

  return <ListItem className={classes.listItem}><Link to={link} className={classes.dropdownLink}>{label}</Link></ListItem>;
}

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      {getRouteFor(props, '/', 'Početna', classes)}
      {getRouteFor(props, '/Pretraga/Novogradnje', 'Novogradnje', classes)}
      {getRouteFor(props, '/Pretraga/Stanovi', 'Stanovi', classes)}
      {getRouteFor(props, '/Pretraga/Kuce', 'Kuće', classes)}
      {getRouteFor(props, '/Pretraga/Prodaja', 'Prodaja', classes)}
      {getRouteFor(props, '/Pretraga/Najam', 'Najam', classes)}
      {getRouteFor(props, '/ProdajNekretninu', 'Prodaj nekretninu', classes)}
      {getRouteFor(props, '/TrazimNekretninu', 'Tražim nekretninu', classes)}
      {getRouteFor(props, '/Kontakt', 'Kontakt', classes)}
      {/* {getRouteFor(props, '/ONama', 'O nama', classes)} */}
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
