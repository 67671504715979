import axios from 'axios';
import config from '../config';

async function apiCallResolver(apiCall) {
  let apiResult = null;

  try
  {
    const response = await apiCall();

    apiResult = response.data;
  }
  catch(e) {
    window.console.error(e);
  }

  return apiResult;
}

export default {
  getHeadline: () => apiCallResolver(() => axios.get(`${config.apiUrl}headline`)),
  getRegisters: () => apiCallResolver(() => axios.get(`${config.apiUrl}registers`)),
  getLatestRealEstates: () => apiCallResolver(() => axios.get(`${config.apiUrl}latest`)),
  getRealEstate: id => apiCallResolver(() => axios.get(`${config.apiUrl}realEstate/${id}`)),
  searchRealEstates: (queryParams, orderBy, page, pageSize, recaptchaToken) => apiCallResolver(() =>
    axios.get(`${config.apiUrl}list`,
      {
        params: {
          r: recaptchaToken,
          publicId: queryParams.publicId,
          location: queryParams.location,
          realEstateType: queryParams.realEstateType,
          offerType: queryParams.offerType,
          areaFrom: queryParams.area.from,
          areaTo: queryParams.area.to,
          roomNumberFrom: queryParams.roomNumber.from,
          roomNumberTo: queryParams.roomNumber.to,
          priceFrom: queryParams.price.from,
          priceTo: queryParams.price.to,
          floorNumberFrom: queryParams.floor.from,
          floorNumberTo: queryParams.floor.to,
          newbuilding: queryParams.newbuilding,
          hasLift: queryParams.hasLift,
          orderBy,
          page,
          pageSize
        }
      }
    ),
  ),
  searchRealEstateByPublicId: (publicId) => apiCallResolver(() =>
    axios.get(`${config.apiUrl}searchByPublicId`,
      {
        params: {
          publicId
        }
      }
    ),
  ),
  sendRealEstateContact: contactData => apiCallResolver(() => axios.post(`${config.apiUrl}realEstateContact`, contactData)),
  sendContact: contactData => apiCallResolver(() => axios.post(`${config.apiUrl}contact`, contactData)),
  submitRealEstate: contactData => apiCallResolver(() => axios.post(`${config.apiUrl}submitRealEstate`, contactData)),
  saveWantedRealEstate: wantedRealEstate => apiCallResolver(() => axios.post(`${config.apiUrl}saveWantedRealEstate`, wantedRealEstate)),
  searchLocations: (term) => apiCallResolver(() =>
    axios.get(`${config.apiUrl}searchLocations`,
      {
        params: {
          term
        }
      }
    ),
  ),
};
