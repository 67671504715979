import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
// import { ReCaptcha } from 'react-recaptcha-v3';

// import config from '../config';
import rivalitasApi from '../rivalitasApi';

// import RecaptchaNotes from '../components/common/RecaptchaNote';
import TermsAndConditionsNote from '../components/common/TermsAndConditionsNote';

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

import TextField from '@material-ui/core/TextField';

import { green } from '@material-ui/core/colors';

const useSnackStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const useStyles = makeStyles(contactUsStyle);

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

function SnackbarContentWrapper(props) {
  const classes = useSnackStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'success']).isRequired,
};

const bgStyle = {
  height: '100%',
  width: '100%',
  objectFit: 'cover'
};

function SubmitRealEstate() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();

  const [recaptchaToken/*, setRecaptchaToken*/] = useState(null);

  const [contactName, setContactName] = useState('');
  const [contactData, setContactData] = useState('');
  const [contactMessage, setContactMessage] = useState('');

  const [showContactConfirmation, setShowContactConfirmation] = React.useState(false);
  const [contactSendSuccess, setContactSendSuccess] = useState(false);

  const handleContactNameChange = e => setContactName(e.target.value);
  const handleContactDataChange = e => setContactData(e.target.value);
  const handleContactMessageChange = e => setContactMessage(e.target.value);

  const handleContactSent = () => {
    setContactName('');
    setContactData('');
    setContactMessage('');
  };

  async function sendContactMessage() {
    handleContactSent();

    const apiResult = await rivalitasApi.submitRealEstate({
      name: contactName,
      contact: contactData,
      message: contactMessage,
      recaptchaToken
    });

    setContactSendSuccess(apiResult && apiResult.success);
    setShowContactConfirmation(true);
  };

  // const verifyCallback = (recaptchaToken) => {
  //   setRecaptchaToken(recaptchaToken);
  // }

  const handleCloseContactConfirmation = () => {
    setShowContactConfirmation(false);
  };

  return (
    <div>
      {/* <ReCaptcha
        sitekey={config.recaptchaKey}
        action="submitRealEstate"
        verifyCallback={verifyCallback}
      /> */}

      <Helmet>
        <title>Rivalitas Nekretnine - Prodaj nekretninu</title>
        <meta name="description" content="Želite prodati svoju nekretninu? Javite nam se." />
        <meta name="keywords" content="proadja nekretnina" />
      </Helmet>

      <div className={classes.bigMap}>
        <img src="/assets/img/submitRe.jpg" alt="Prodaj nekretninu" style={bgStyle} />
      </div>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <br />
            <h2 className={classes.title}>Želite prodati Vašu nekretninu?</h2>
            <GridContainer>
              <GridItem md={12} sm={12} xs={12}>
                <h3>Pošaljite nam detalje i javiti ćemo Vam se u najkraćem roku</h3>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Vaše ime"
                  fullWidth
                  value={contactName}
                  onChange={handleContactNameChange}
                />
                <TextField
                  margin="dense"
                  label="Kontakt (Email ili telefon) *"
                  fullWidth
                  value={contactData}
                  onChange={handleContactDataChange}
                  error={!contactData}
                  helperText={!contactData ? 'Molimo unesite Vaš kontakt kako bi Vas mogli kontaktirati' : null}
                />
                <TextField
                  margin="dense"
                  label="Opis nekretnine"
                  fullWidth
                  multiline
                  rows="4"
                  value={contactMessage}
                  onChange={handleContactMessageChange}
                  helperText="Molimo ukratko opišite Vašu nekretninu"
                />
                <Button onClick={sendContactMessage} color="primary" disabled={!contactData} variant="contained">
                  Pošalji
                </Button>
                <TermsAndConditionsNote />
                {/* <RecaptchaNotes /> */}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <br />
      <br />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showContactConfirmation}
        autoHideDuration={6000}
        onClose={handleCloseContactConfirmation}
      >
        <SnackbarContentWrapper
          variant={contactSendSuccess ? 'success' : 'error'}
          className={classes.margin}
          message={contactSendSuccess ? 'Vaša poruka je poslana' : 'Greška: molimo pokušajte ponovo'}
          onClose={handleCloseContactConfirmation}
        />

      </Snackbar>
    </div>
  );
}

export default SubmitRealEstate;
