const config = {
};

if (process.env.NODE_ENV === 'production') {
  var loc = window.location, ws_uri;

  if (loc.protocol === 'https:') {
  	ws_uri = 'wss:';
  } else {
  	ws_uri = 'ws:';
  }
  ws_uri += '//' + loc.host + '/ws';

  config.websocketUrl = ws_uri;
  config.serverUrl = '/';
} else {
  config.serverUrl = 'http://localhost:5000/';
  config.websocketUrl = 'ws://localhost:5000/ws';
}

config.apiUrl = config.serverUrl + 'Public/';

config.recaptchaKey = '6LcUcsAUAAAAAOxL5zV5Wq6J1JfLpSLCsxQF21eA';

export default config;
