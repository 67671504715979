import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import rivalitasApi from '../../rivalitasApi';
// import config from '../../config';
import { stringToPositiveNumber } from '../../util';
// import RecaptchaNote from '../../components/common/RecaptchaNote';

import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';

import Apps from '@material-ui/icons/Apps';
import Table from '@material-ui/icons/Toc';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import Pagination from "components/Pagination/Pagination.js";

import SearchBox from '../../components/common/SearchBox';
import RealEstatesCardView from '../../components/common/RealEstatesCardView';
import RealEstatesListView from '../../components/common/RealEstatesListView';

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const defaultSearchParams = {
  publicId: null,
  location: null,
  realEstateType: null,
  offerType: null,
  price: {
    from: null,
    to: null
  },
  area: {
    from: null,
    to: null
  },
  roomNumber: {
    from: null,
    to: null
  },
  floor: {
    from: null,
    to: null
  },
  newbuilding: false,
  hasLift: false
};

const useStyles = makeStyles((theme) => {
  return {
    ...contactUsStyle,
    resultTitle: {
      paddingTop: '20px',
      paddingBottom: '20px',
      backgroundColor: '#3C4858',
      color: 'white',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      marginTop: '130px',
    },
    resultContent: {
      margin: '20px'
    },
    justifyContentCenter: {
      "& > ul": {
        justifyContent: "center!important"
      }
    },
    toolbarButtons: {
      marginLeft: 'auto',
    },
    activeToggle: {
    },
    inactiveToggle: {
      opacity: '0.6'
    },
    loading: {
      position: 'fixed',
      width: '100%',
      top: '112px'
    }
  };
});

function createPagerData(page, pageSize, pageCount, count, onClick, maxLinkCount=6) {
  const result = [];

  const pageParams = { page, pageSize, pageCount, count, maxLinkCount };

  function hasNext(pageNumber, pagerParams) {
    return pageNumber < pagerParams.pageCount;
  }

  function hasPrevious(pageNumber) {
    return pageNumber > 1;
  }

  let startPage = pageParams.page - ((pageParams.page - 1) % pageParams.maxLinkCount);
  let endPage = startPage + pageParams.maxLinkCount - 1;

  if (endPage > pageParams.pageCount) {
    endPage = pageParams.pageCount;
  }

  const hasFarPrevious = startPage > pageParams.maxLinkCount;
  const hasFarNext = ( pageParams.pageCount - endPage ) >= 1;

  if (hasPrevious(pageParams.page)) {
    result.push( { text: 'Prethodna', onClick: () => onClick(pageParams.page - 1) } );
  }
  if (hasFarPrevious) {
    const farPreviousLabel = ( startPage - pageParams.maxLinkCount ) + '-' + ( startPage - 1 );

    result.push( { text: farPreviousLabel, onClick: () => onClick(startPage - 1) } );
  }

  for (let i = startPage; i <= endPage; ++i) {
    const active = pageParams.page === i;

    result.push( { text: i, active, onClick: active ? () => {} : () => onClick(i) } );
  }

  if (hasFarNext) {
    const farNextFrom = endPage + 1;
    let farNextTo = farNextFrom + pageParams.maxLinkCount - 1;
    if (farNextTo > pageParams.pageCount) {
      farNextTo = pageParams.pageCount;
    }

    const farNextLabel = farNextFrom + '-' + farNextTo;

    result.push( { text: farNextLabel, onClick: () => onClick(endPage + 1) });
  }

  if (hasNext(pageParams.page, pageParams)) {
    result.push( { text: 'Slijedeća', onClick: () => onClick(pageParams.page + 1) });
  }

  return result;
}

// function sleep(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

// const isRecaptchaReady = () =>
//   typeof window !== 'undefined' &&
//   typeof window.grecaptcha !== 'undefined' &&
//   typeof window.grecaptcha.execute !== 'undefined';

function parsePage(param) {
  const result = stringToPositiveNumber(param);
  return result || 1;
}


// async function getRecaptchaToken() {
//   if (!isRecaptchaReady()) {
//     let retryCount = 1;
//     while (retryCount < 5) {
//       await sleep(200);
//       retryCount += 1;

//       if (isRecaptchaReady()) {
//         break;
//       }
//     }
//   }

//   const token = await window.grecaptcha.execute(config.recaptchaKey, { action: 'realEstateQuery' });
//   return token;
// }

function SearchResult({ history, match, queryParams, title, description, keywords }) {
  // const [recaptchaToken, setRecaptchaToken] = useState(null);

  const [page, setPage] = useState(parsePage(match.params.page));
  const [pageSize] = useState(12);
  const [realEstateQueryResult, setRealEstateQueryResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const [orderBy, setOrderBy] = useState('date');

  // async function getAndSaveNewToken() {
  //   const token = await getRecaptchaToken();
  //   setRecaptchaToken(token);
  //   return token;
  // }

  function processPageChange(newPage) {
    if (newPage === page) {
      return;
    }

    const redirectPage = newPage > 1 ? `/${newPage}` : '';

    let redirectUrl = `${match.path.replace('/:page?', redirectPage)}${history.location.search}`;
    history.push(redirectUrl);
  }

  async function loadRealEstatesBasedOnQueryParams() {
    setLoading(true);

    // let tokenToUse = recaptchaToken || await getAndSaveNewToken();
    const tokenToUse = null;

    const result = await rivalitasApi.searchRealEstates(queryParams ? queryParams : defaultSearchParams, orderBy, page, pageSize, tokenToUse);
    setRealEstateQueryResult(result);

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    setLoading(false);

    // Token was reused, initiate new one
    // setRecaptchaToken(null);
    // getAndSaveNewToken();
  }

  useEffect(() =>{
    if (!queryParams ) {
      return;
    }

    loadRealEstatesBasedOnQueryParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, page, orderBy]);

  useEffect(() =>{
    setPage(parsePage(match.params.page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.page]);

  const classes = useStyles();

  const hasResult = !!realEstateQueryResult;

  const pagerData = hasResult ? createPagerData(page, pageSize, realEstateQueryResult.pageCount, realEstateQueryResult.count, processPageChange) : [];

  function renderRealEstateResult() {
    if (!hasResult) {
      return null;
    }

    if (displayTable) {
      return <RealEstatesListView realEstates={realEstateQueryResult.data} />;
    } else {
      return <RealEstatesCardView realEstates={realEstateQueryResult.data} />;
    }
  }

  return (
    <div>
      <Helmet>
        <title>{`${title} - Stranica ${page}`}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>

      {loading &&
        <div className={classNames(classes.loading)}>
          <LinearProgress />
        </div>
      }

      <div className={classNames(classes.main, classes.mainRaised, classes.mainMargin)}>
        <div className={classes.contactContent}>
          <Toolbar className={classes.resultTitle}>
            {!loading &&
              <Typography variant="h6">
                Broj pronađenih nekretnina: {realEstateQueryResult ? realEstateQueryResult.count : 0}
              </Typography>
            }

            <Hidden smDown>
              <ButtonGroup color="secondary" className={classes.toolbarButtons}>
                <Button className={displayTable ? classes.activeToggle : classes.inactiveToggle} onClick={() => setDisplayTable(false)}>
                  <Tooltip title="Tablični prikaz" placement="top">
                    <Apps />
                  </Tooltip>
                </Button>
                <Button className={displayTable ? classes.inactiveToggle : classes.activeToggle} onClick={() => setDisplayTable(true)}>
                  <Tooltip title="Prikaži listu" placement="top">
                    <Table />
                  </Tooltip>
                </Button>
              </ButtonGroup>
            </Hidden>
          </Toolbar>

          <div className={classes.resultContent}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={7} lg={9}>
                <Grid container>
                  <Hidden only="xs">
                    <Grid item sm={6} md={8} lg={11} />
                  </Hidden>
                  <Hidden mdUp>
                    <br />
                    <br />
                    <br />
                  </Hidden>
                  <Grid item xs={12} sm={6} md={4} lg={1}>
                    <FormControl className={classes.formControl} fullWidth={true}>
                      <InputLabel>Prikaži najprije</InputLabel>
                      <Select
                        value={orderBy}
                        onChange={e => setOrderBy(e.target.value)}
                      >
                        <MenuItem value="date">Najnovije</MenuItem>
                        <MenuItem value="priceDown">S višom cijenom</MenuItem>
                        <MenuItem value="priceUp">S nižom cijenom</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <div className={classes.justifyContentCenter}>
                  <Pagination pages={pagerData} />
                </div>

                {renderRealEstateResult()}

                <div className={classes.justifyContentCenter}>
                  <Pagination pages={pagerData} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={3}>
                {!!queryParams &&
                  <SearchBox
                    stretchHeight={false}
                    title="Izmjeni rezultate"
                    searchParams={queryParams}
                  />
                }
              </Grid>
            </Grid>
            {/* <RecaptchaNote /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(SearchResult);
