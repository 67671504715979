import React from 'react';

import SearchResult from '../implementation/SearchResult';


const searchParams = {
  publicId: null,
  location: 'Zagreb',
  realEstateType: 'Kuće',
  offerType: 'Najam',
  price: {
    from: null,
    to: null
  },
  area: {
    from: null,
    to: null
  },
  roomNumber: {
    from: null,
    to: null
  },
  floor: {
    from: null,
    to: null
  },
  newbuilding: false,
  hasLift: false
};

function NajamKuceZagreb() {

  const title = 'Rivalitas Nekretnine - Najam kuća u Zagrebu - Kuće za iznajmljivanje, Zagreb i okolica';
  const description = 'Tražite kuću u Zagrebu za iznajmljivanje? Provjerite našu ponudu kuća spremnih za najam. Pretražite prema cijeni i kvadraturi';
  const keywords = 'agencija, nekretnine, stanovi, kuće, novogradnje, najam, prodaja';

  return (
    <SearchResult queryParams={searchParams} title={title} description={description} keywords={keywords} />
  );
}

export default NajamKuceZagreb;
