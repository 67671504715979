import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Carousel from "react-slick";

import { createImageUrl } from '../../util';

// const imageStyle = {
//   height: '55vh',
//   objectFit: 'cover'
// };

const headlineStyle = {
  fontSize: '2.0em',
  textShadow: '1px 1px #000000',
  color: '#ffffff'
};
const headlineStyleEmpty = {
  fontSize: '2.0em',
  textShadow: '1px 1px #000000',
  color: '#5ab9e8'
};

function getRealEstateImage(r) {
  if (!r || !r.image) {
    return '/assets/img/about-us-bg.jpg';
  }

  // TODO - select image based on device (use ismobilejs) !!!!!!
  return createImageUrl(r.urlFriendlyHeadline, r.image.large);
}

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};

function HomepageSlider({ realEstates }) {

  const slides = !realEstates || realEstates.length <= 0
    ? (
      <div className="slide-container">
        <img alt="Rivalitas nekretnine" src="/assets/img/about-us-bg.jpg" className="slick-image" />
        <div className="slick-caption">
          <h4 style={headlineStyleEmpty}>Rivalitas Nekretnine</h4>
          <h5 style={headlineStyleEmpty}>Velika ponuda nekretnina za prodaju i najam</h5>
        </div>
      </div>
    )
    : realEstates.map(r => (
      <div key={r.publicId} class="slide-container">
        <img alt={r.headline} src={getRealEstateImage(r)} className="slick-image" />
        <div className="slick-caption">
          <h4 style={headlineStyle}>
            <Link style={headlineStyle} to={`/Nekretnine/${r.urlFriendlyHeadline}/${r.publicId}`}>{r.headline}</Link>
          </h4>
        </div>
      </div>
    ));

  return (
    <div id="carousel" style={{minHeight: '55vh', height: '100%'}}>
      <Carousel {...settings}>
        {slides}
      </Carousel>
    </div>
  );
}

HomepageSlider.defaultProps = {
  realEstates: [],
};

HomepageSlider.propTypes = {
  realEstates: PropTypes.arrayOf(PropTypes.shape({
    publicId: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    price: PropTypes.number,
    image: PropTypes.shape({
      large: PropTypes.string.isRequired,
      midi: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired
    })
  })).isRequired,
};

export default React.memo(HomepageSlider);
