/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
// import { loadReCaptcha } from 'react-recaptcha-v3';

// import config from './config';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import "./assets/scss/material-kit-pro-react.scss?v=1.8.0";

import Main from './Main';

const theme = createMuiTheme({
  palette: {
    primary: {
      contrastText: '#fff',
      dark: '#3C4858',
      light: '#7986cb',
      main: '#3ca8df'
    },
    secondary: {
      contrastText: '#3ca8df',
      dark: '#c51162',
      light: '#ff4081',
      main: '#fff'
    }
  },
});

function App() {
  // React.useEffect(() => {
  //   loadReCaptcha(config.recaptchaKey);
  // }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Main />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
