import React from 'react';

import SearchResult from '../implementation/SearchResult';


const searchParams = {
  publicId: null,
  location: null,
  realEstateType: 'Stanovi',
  offerType: null,
  price: {
    from: null,
    to: null
  },
  area: {
    from: null,
    to: null
  },
  roomNumber: {
    from: null,
    to: null
  },
  floor: {
    from: null,
    to: null
  },
  newbuilding: false,
  hasLift: false
};

function Stanovi() {

  const title = 'Rivalitas Nekretnine - Prodaja i najam stanova - Jednosobni, dvosobni i trosobni stanovi';
  const description = 'Prodaja i najam stanova na području Republike Hrvatske - izaberite jednosobni, dvosobni, trosobni ili višesobni stan na prodaju ili najam te grad, mjesto, ulicu.';
  const keywords = 'agencija, nekretnine, stanovi, kuće, novogradnje, najam, prodaja';

  return (
    <SearchResult queryParams={searchParams} title={title} description={description} keywords={keywords} />
  );
}

export default Stanovi;
