import PropTypes from 'prop-types';
import React, { useEffect, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles";

import queryString from 'query-string'

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Hidden from "@material-ui/core/Hidden";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomInput/CustomSelect.js";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

import ApplicationContext from '../../ApplicationContext';

import { numberToString, stringToPositiveNumber, stringToNumber } from '../../util';

import { realEstateFloors } from '../../constants';

// Alternative background color: #84ceeb
// backgroundColor: '#aaabb8',

const useStyles = makeStyles(() => ({
  ...contactUsStyle,
  searchBoxSimple: {
    position: 'relative',
    backgroundColor: '#3C4858',
    padding: '20px',
    color: 'white',
  },
  searchBox: {
    position: 'relative',
    height: '100%',
    backgroundColor: '#3C4858',
    padding: '20px',
    color: 'white',
  },
  searchTitle: {
    ...contactUsStyle.title,
    color: '#e4f1fe',
    marginTop: '5px',
    marginBottom: 0
  },
  searchCheckbox: {
    color: 'white'
  },
  searchButton: {
    width: '100%',
    marginTop: '60px',
    marginLeft: '5px',
    marginRight: '5px',
    bottom: '15px'
  }
}));

function cleanNullValues(obj) {
  for (var propName in obj) { 
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
}


function SearchBox({ history, searchParams, title, stretchHeight }) {
  const [publicId, setPublicId] = useState(searchParams.publicId);
  const [location, setLocation] = useState(searchParams.location);
  const [realEstateType, setRealEstateType] = useState(searchParams.realEstateType);
  const [offerType, setOfferType] = useState(searchParams.offerType);
  const [priceFrom, setPriceFrom] = useState(searchParams.price.from);
  const [priceTo, setPriceTo] = useState(searchParams.price.to);
  const [areaFrom, setAreaFrom] = useState(searchParams.area.from);
  const [areaTo, setAreaTo] = useState(searchParams.area.to);
  const [roomNumberFrom, setRoomNumberFrom] = useState(searchParams.roomNumber.from);
  const [roomNumberTo, setRoomNumberTo] = useState(searchParams.roomNumber.to);
  const [floorFrom, setFloorFrom] = useState(searchParams.floor.from);
  const [floorTo, setFloorTo] = useState(searchParams.floor.to);
  const [newbuilding, setNewbuilding] = useState(searchParams.newbuilding);
  const [hasLift, setHasLift] = useState(searchParams.hasLift);

  function refreshSearchParams() {
    setPublicId(searchParams.publicId);
    setLocation(searchParams.location);
    setRealEstateType(searchParams.realEstateType);
    setOfferType(searchParams.offerType);
    setPriceFrom(searchParams.price.from);
    setPriceTo(searchParams.price.to);
    setAreaFrom(searchParams.area.from);
    setAreaTo(searchParams.area.to);
    setRoomNumberFrom(searchParams.roomNumber.from);
    setRoomNumberTo(searchParams.roomNumber.to);
    setFloorFrom(searchParams.floor.from);
    setFloorTo(searchParams.floor.to);
    setNewbuilding(searchParams.newbuilding);
    setHasLift(searchParams.hasLift);
  }

  useEffect(() =>{
    refreshSearchParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  function createSearchQuery() {
    const searchQuery = {
      pid: publicId ? publicId : null,
      lq: location ? location : null,
      ret: realEstateType ? realEstateType : null,
      ot: offerType ? offerType : null,
      pf: priceFrom,
      pt: priceTo,
      af: areaFrom,
      at: areaTo,
      rf: roomNumberFrom,
      rt: roomNumberTo,
      ff: floorFrom,
      ft: floorTo,
      nb: newbuilding ? 'Novogradnje' : null,
      lft: hasLift ? 'ImaLift' : null
    };

    cleanNullValues(searchQuery);

    return queryString.stringify(searchQuery);
  }

  const appContext = useContext(ApplicationContext);

  const classes = useStyles();

  const applyNumericFnc = (e, fnc) => {
    fnc(stringToPositiveNumber(e.target.value));
  };

  const applyNegativeAndPositiveNumericFnc = (e, fnc) => fnc(stringToNumber(e.target.value));

  function doSearch() {
    history.push(`/Pretraga/Napredna?${createSearchQuery()}`);
  }

  return (
    <div className={stretchHeight ? classes.searchBox : classes.searchBoxSimple}>
      {stretchHeight &&
        <Hidden smDown>
          <br />
          <br />
          <br />
          <br />
        </Hidden>
      }

      {!!title &&
        <h3 className={classes.searchTitle}>{title}</h3>
      }
      <GridContainer>
        <GridItem xs={6}>
          <CustomInput labelText="Broj nekretnine" white formControlProps={{ fullWidth: true }}
            inputProps={{
              value: publicId || '',
              onChange: e => setPublicId(e.target.value)
            }}
          />
        </GridItem>
        <GridItem xs={6}>
          <CustomInput labelText="Lokacija" white formControlProps={{ fullWidth: true }}
            inputProps={{
              value: location || '',
              onChange: e => setLocation(e.target.value)
            }}
          />
        </GridItem>

        <GridItem xs={6}>
          <CustomSelect labelText="Vrsta nekretnine" white formControlProps={{ fullWidth: true }}
            inputProps={{
              value: realEstateType || '',
              onChange: e => setRealEstateType(e.target.value)
            }}
            items={appContext.registers.realEstateTypes.map(t => ({
              value: t,
              label: t
            }))}
          />

        </GridItem>
        <GridItem xs={6}>
          <CustomSelect labelText="Vrsta ponude" white formControlProps={{ fullWidth: true }}
            inputProps={{
              value: offerType || '',
              onChange: e => setOfferType(e.target.value)
            }}
            items={appContext.registers.offerTypes.map(t => ({
              value: t,
              label: t
            }))}
          />
        </GridItem>

        <GridItem xs={6}>
          <CustomInput labelText="Površina od" white formControlProps={{ fullWidth: true }}
            inputProps={{
              value: numberToString(areaFrom) || '',
              onChange: e => applyNumericFnc(e, setAreaFrom),
            }}
          />
        </GridItem>
        <GridItem xs={6}>
          <CustomInput labelText="Površina do" white formControlProps={{ fullWidth: true }}
            inputProps={{
              value: numberToString(areaTo) || '',
              onChange: e => applyNumericFnc(e, setAreaTo),
            }}
          />
        </GridItem>

        <GridItem xs={6}>
          <CustomInput labelText="Sobnost od" white formControlProps={{ fullWidth: true }}
            inputProps={{
              value: numberToString(roomNumberFrom) || '',
              onChange: e => applyNumericFnc(e, setRoomNumberFrom),
            }}
          />
        </GridItem>
        <GridItem xs={6}>
          <CustomInput labelText="Sobnost do" white formControlProps={{ fullWidth: true }}
            inputProps={{
              value: numberToString(roomNumberTo) || '',
              onChange: e => applyNumericFnc(e, setRoomNumberTo),
            }}
          />
        </GridItem>

        <GridItem xs={6}>
          <CustomInput labelText="Cijena od (€)" white formControlProps={{ fullWidth: true }}
            inputProps={{
              value: numberToString(priceFrom) || '',
              onChange: e => applyNumericFnc(e, setPriceFrom),
            }}
          />
        </GridItem>
        <GridItem xs={6}>
          <CustomInput labelText="Cijena do (€)" white formControlProps={{ fullWidth: true }}
            inputProps={{
              value: numberToString(priceTo) || '',
              onChange: e => applyNumericFnc(e, setPriceTo),
            }}
          />
        </GridItem>

        <GridItem xs={6}>
          <CustomSelect labelText="Kat od" white formControlProps={{ fullWidth: true }}
            inputProps={{
              value: numberToString(floorFrom),
              onChange: e => applyNegativeAndPositiveNumericFnc(e, setFloorFrom),
            }}
            items={realEstateFloors.map(f => ({
              value: numberToString(f.value),
              label: f.label
            }))}
          />
        </GridItem>
        <GridItem xs={6}>
          <CustomSelect labelText="Kat od" white formControlProps={{ fullWidth: true }}
            inputProps={{
              value: numberToString(floorTo) || '',
              onChange: e => applyNegativeAndPositiveNumericFnc(e, setFloorTo),
            }}
            items={realEstateFloors.map(f => ({
              value: numberToString(f.value),
              label: f.label
            }))}
          />
        </GridItem>

        <GridItem xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                classes={{
                  root: classes.searchCheckbox
                }}
                color="primary"
                checked={newbuilding}
                onChange={e => setNewbuilding(e.target.checked)}
              />
            }
            label="Novogradnja"
          />
        </GridItem>
        <GridItem xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                classes={{
                  root: classes.searchCheckbox
                }}
                color="primary"
                checked={hasLift}
                onChange={e => setHasLift(e.target.checked)}
              />
            }
            label="Ima lift"
          />
        </GridItem>
      </GridContainer>

      <Button variant="outlined" color="secondary" className={classes.searchButton} onClick={doSearch}>
        Traži
      </Button>
    </div >
  );
}

SearchBox.defaultProps = {
  stretchHeight: true,
  searchParams: {
    publicId: '',
    location: '',
    realEstateType: '',
    offerType: '',
    price: {
      from: null,
      to: null
    },
    area: {
      from: null,
      to: null
    },
    roomNumber: {
      from: null,
      to: null
    },
    floor: {
      from: null,
      to: null
    },
    newbuilding: false,
    hasLift: false
  }
};

SearchBox.propTypes = {
  stretchHeight: PropTypes.bool.isRequired,
  title: PropTypes.string,
  searchParams: PropTypes.shape({
    publicId: PropTypes.string,
    location: PropTypes.string,
    realEstateType: PropTypes.string,
    offerType: PropTypes.string,
    price: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number
    }).isRequired,
    area: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number
    }).isRequired,
    roomNumber: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number
    }).isRequired,
    floor: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number
    }).isRequired,
    newbuilding: PropTypes.bool.isRequired,
    hasLift: PropTypes.bool.isRequired
  }).isRequired
};

export default React.memo(withRouter(SearchBox));
