import React from 'react';
import { Helmet } from 'react-helmet';

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contactStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/contactStyle.js";

const useStyles = makeStyles(aboutUsStyle);
const useContactStyles = makeStyles(contactStyle);

function Sellers() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const contactClasses = useContactStyles();

  return (
    <div>
      <Helmet>
        <title>Rivalitas Nekretnine - Detalji za kupce</title>
        <meta name="description" content="Detalji za kupce" />
        <meta name="keywords" content="Detalji za kupce" />
      </Helmet>

      <Parallax image="/assets/img/sellers.jpg" filter="dark" small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Za prodavatelje</h1>
              <h4>
                <i>Mi brinemo za sve, Vi se samo trebate odlučiti</i>
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={contactClasses.aboutUs}>
            <GridContainer>
              <GridItem
                md={8}
                sm={8}
                className={classNames(contactClasses.mrAuto, contactClasses.mlAuto)}
              >
                <h3 className={classNames(contactClasses.title, contactClasses.textCenter)}>
                  Poštovani klijenti
                </h3>
                <p>
                  <strong>Uz pomoć našeg iskusnog i educiranog tima vi ćete:</strong>
                </p>
                <ul>
                  <li>u najkraćem mogućem roku naći ozbiljnog kupca za svoju nekretninu</li>
                  <li>izbjeći obavljanje svih poslova oko provjere dokumentacije, ugovaranja i prijenosa vlasništva</li>
                  <li>biti sigurni da će se kupoprodaja obaviti profesionalno i u skladu sa zakonom</li>
                  <li>izbjeći stresne situacije vezane uz cjelokupni postupak kupoprodaje</li>
                </ul>
                <p>
                  <strong>Za vas radimo:</strong>
                </p>
                <ul>
                  <li>savjetovanje i dajemo pravnu pomoć</li>
                  <li>obavještavanje o tržišnim prilikama i uvjetima koji se odnose na prodaju nekretnine</li>
                  <li>oglašavanje nekretnina za prodaju, najam ili zamjenu</li>
                  <li>provjeru dokumentacije i vlasništva nekretnine za koju posredujemo</li>
                  <li>vođenje kompletnog procesa prijenosa vlasništva u zemljišnim knjigama</li>
                  <li>prijenos režija na novog vlasnika</li>
                </ul>
                <p>
                  Uvijek na raspolaganju, Vaše <strong>Rivalitas Nekretnine.</strong>
                </p>
                <p>
                  Agencija Rivalitas je član <a href="https://www.hgk.hr/" target="_blank" rel="noopener noreferrer">HGK</a> i posluje sukladno <a href="https://narodne-novine.nn.hr/clanci/sluzbeni/2007_10_107_3128.html" target="_blank" rel="noopener noreferrer">Zakonu</a> o posredovanju u prometu nekretnina.
                </p>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sellers;
