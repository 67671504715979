import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import rivalitasApi from '../rivalitasApi';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Hidden from "@material-ui/core/Hidden";

import Euro from '@material-ui/icons/EuroSymbol';
import Folder from '@material-ui/icons/FolderOpen';
import Shield from "@material-ui/icons/VerifiedUserOutlined";
import Sun from "@material-ui/icons/WbSunnyOutlined";
import Arrow from "@material-ui/icons/ArrowForward";

import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";

import HomepageSlider from '../components/common/HomepageSlider';
import SearchBox from '../components/common/SearchBox';
import RealEstatesCardView from '../components/common/RealEstatesCardView';

const useBasicStyles = makeStyles(theme => {
  return {
    title: {
      flexGrow: 1,
    },
    left: {
      float: 'left'
    },
    right: {
      float: 'right'
    },
    appBar: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '110px',
      },
    }
  };
});

const useStyles = makeStyles(testimonialsStyle);

const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

function Home() {

  const [headline, setHeadline] = useState([]);
  const [latestRealEstates, setLatestRealEstates] = useState([]);

  async function getHeadline() {
    const result = await rivalitasApi.getHeadline();
    if (result) {
      setHeadline(result);
    }
  }
  async function getLatestRealEstates() {
    const result = await rivalitasApi.getLatestRealEstates();

    if (result && result.data && result.data.length > 0) {
      setLatestRealEstates(chunk(result.data, 4));
    }
  }

  useEffect(() => {
    getHeadline();
    getLatestRealEstates();
  }, []);

  const basicClasses = useBasicStyles();
  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <title>Rivalitas Nekretnine - Licencirana agencija za nekretnine</title>
        <meta name="description" content="Rivalitas Nekretnine - okupili smo tim profesionalaca koji će Vam pomoći da u najkraćem roku prodate ili pronađete nekretninu koja Vam odgovara uz stručnu pomoć i pravnu sigurnost." />
        <meta name="keywords" content="agencija, nekretnine, stanovi, kuće, novogradnje, najam, prodaja" />
      </Helmet>

      <Hidden smDown>
        <Grid container spacing={0}>
          <Grid item sm={12} md={9}>
            <HomepageSlider realEstates={headline} />
          </Grid>
          <Grid item sm={12} md={3}>
            <SearchBox />
          </Grid>
        </Grid>
      </Hidden>

      <AppBar position="static" className={basicClasses.appBar}>
        <Toolbar>
          <Typography variant="h6" className={basicClasses.title} align="center">
            Agencija za posredovanje u prometu nekretninama
          </Typography>
        </Toolbar>
      </AppBar>

      <div className={classes.container}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={6}
            md={6}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h2 className={classes.title}>Naše usluge</h2>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card testimonial>
              <CardBody plain>
                <Euro color="primary" fontSize="large" />
                <h4 className={classes.title}>Kupovina</h4>
                <p>Kupujete <Link to="/Prodaja/Stanovi">stan</Link> ili <Link to="/Prodaja/Kuce">kuću</Link>? Agencija <strong>Rivalitas</strong> posjeduje višegodišnje iskustvo u posredovanju. Obratite nam se.</p>
                <Link to="/Kupci">Detaljnije</Link>
                <br />
                <br />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card testimonial>
              <CardBody plain>
                <Folder color="primary" fontSize="large" />
                <h4 className={classes.title}>Prodaja</h4>
                <p>Trebate pomoć oko prodaje? Obratite nam se i cijeli postupak ćemo obaviti brzo i profesionalno.</p>
                <Link to="/Prodavatelji">Detaljnije</Link>
                <br />
                <br />
                <br />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card testimonial>
              <CardBody plain>
                <Sun color="primary" fontSize="large" />
                <h4 className={classes.title}>Energetski certifikati</h4>
                <p>Nudimo izradu <Link to="/EnergetskiCertifikati">energetskih certifikata</Link> po povoljnim cijenama. Obratite nam se s povjerenjem.</p>
                <Link to="/EnergetskiCertifikati">Detaljnije</Link>
                <br />
                <br />
                <br />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card testimonial>
              <CardBody plain>
                <Shield color="primary" fontSize="large" />
                <h4 className={classes.title}>Rivalitas jamstvo</h4>
                <p>Jamčimo Vam urednu dokumentaciju nekretnina, pravnu sigurnost u poslovanju i stručnost naših agenata. {(moment().year() - 2006)} godina na tržištu je potvrda naše kvalitete.</p>
                <Link to="/ONama">Detaljnije o nama</Link>
              </CardBody>
            </Card>
          </GridItem>
          
        </GridContainer>
      </div>

      {(latestRealEstates.length > 0) &&
        <React.Fragment>
          <br />
          <div className={classes.container}>
            <GridContainer alignItems="center">
              <GridItem xs={6}>
                <Typography align="left" variant="h5">
                  Najnovije
                </Typography>
              </GridItem>
              <GridItem xs={6}>
                <Typography align="right">
                  <Button
                    component={Link}
                    to="/Pretraga/Sve"
                    color="primary"
                    className={classes.button}
                    endIcon={<Arrow />}
                  >
                    Sve nekretnine
                  </Button>
                </Typography>
                
              </GridItem>
            </GridContainer>
            <hr />
            <RealEstatesCardView realEstates={latestRealEstates[0]} />

            <hr />

            <GridContainer alignItems="center">
              <GridItem xs={6}>
                <Typography align="left" variant="h5">
                  Želite vidjeti Vašu nekretninu ovdje?
                </Typography>
              </GridItem>
              <GridItem xs={6}>
                <Typography align="right">
                  <Button
                    component={Link}
                    to="/ProdajNekretninu"
                    color="primary"
                    className={classes.button}
                    endIcon={<Arrow />}
                  >
                    Prodaj
                  </Button>
                </Typography>
                
              </GridItem>
            </GridContainer>

            <hr />

            {(latestRealEstates.length > 1) &&
              <RealEstatesCardView realEstates={latestRealEstates[1]} />
            }
          </div>

        </React.Fragment>
      }
    </div>
  );
}

export default Home;
