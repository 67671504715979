import React from 'react';

import SearchResult from '../implementation/SearchResult';


const searchParams = {
  publicId: null,
  location: null,
  realEstateType: 'Stanovi',
  offerType: null,
  price: {
    from: null,
    to: null
  },
  area: {
    from: null,
    to: null
  },
  roomNumber: {
    from: 3,
    to: 3
  },
  floor: {
    from: null,
    to: null
  },
  newbuilding: false,
  hasLift: false
};

function TrosobniStanovi() {

  const title = 'Rivalitas Nekretnine - Trosobni stanovi - prodaja trosobnih stanova';
  const description = 'Tražite trosobni stan? Pregledajte našu ponudu trosobnih stanova na području cijele Republike Hrvatske';
  const keywords = 'agencija, nekretnine, stanovi, kuće, novogradnje, najam, prodaja';

  return (
    <SearchResult queryParams={searchParams} title={title} description={description} keywords={keywords} />
  );
}

export default TrosobniStanovi;
