import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { isMobile } from "react-device-detect";
// import { ReCaptcha } from 'react-recaptcha-v3'

import { makeStyles } from '@material-ui/core/styles';

import rivalitasApi from '../rivalitasApi';
// import RecaptchaNotes from '../components/common/RecaptchaNote';
import TermsAndConditionsNote from '../components/common/TermsAndConditionsNote';

import RealEstateImageGallery from '../components/common/RealEstateImageGallery';
import CustomButton from '../components/CustomButtons/Button';

import Grid from "@material-ui/core/Grid";
import LinearProgress from '@material-ui/core/LinearProgress';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Mail from "@material-ui/icons/MailOutline";
import MobilePhone from "@material-ui/icons/PhoneAndroid";
import Phone from "@material-ui/icons/Phone";

import { green } from '@material-ui/core/colors';

import contactUsStyle from 'assets/jss/material-kit-pro-react/views/contactUsStyle.js';

import { formatMoney, createRealEstateSimilarSearchQueries } from '../util';

// import config from '../config';

const useSnackStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const useStyles = makeStyles((theme) => {
  return {
    ...contactUsStyle,
    subheaderContainer: {
      marginTop: '20px'
    },
    priceBig: {
      fontSize: '1.9em',
      color: theme.palette.primary.main
    },
    priceSmall: {
      marginLeft: '10px',
      fontSize: '1.2em',
      color: theme.palette.grey['700']
    },
    title: {
      ...contactUsStyle.title,
      marginLeft: '15px',
    },
    resultTitle: {
      marginTop: '15px'
    },
    resultContent: {
      marginTop: '80px',
      marginBottom: '40px',
      paddingTop: '5px'
    },
    subheadline: {
      marginLeft: 'auto',
    },
    realEstateContent: {
      marginLeft: '30px',
      marginRight: '30px',
      paddingBottom: '120px'
    },
    regularLink: {
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    },
    similarQueriesContainer: {
      textAlign: 'right'
    },
    mapContainer: {
      paddingLeft: '15px',
    },
    mapFrame: {
      border: `1px solid ${theme.palette.grey['400']}`
    },
    agentContact: {
      float: 'right'
    },
    loading: {
      position: 'fixed',
      width: '100%',
      top: '112px'
    }
}});

const printString = value => value || '';

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

function SnackbarContentWrapper(props) {
  const classes = useSnackStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'success']).isRequired,
};

function RealEstate({match}) {
  const [notFound, setNotFound] = useState(false);
  const [realEstate, setRealEstate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showContactDialog, setShowContactDialog] = useState(false);

  const [contactName, setContactName] = useState('');
  const [contactData, setContactData] = useState('');
  const [contactMessage, setContactMessage] = useState('');

  const [showContactConfirmation, setShowContactConfirmation] = React.useState(false);
  const [contactSendSuccess, setContactSendSuccess] = useState(false);

  const [recaptchaToken/*, setRecaptchaToken*/] = useState(null);

  async function loadRealEstateData() {
    if (!match.params.id) {
      setNotFound(true);
      return;
    }

    setLoading(true);
    const data = await rivalitasApi.getRealEstate(match.params.id);

    if (data) {
      setRealEstate(data);
      setLoading(false);
    } else {
      setNotFound(true);
    }
  }

  useEffect(() =>{
    loadRealEstateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params]);

  const classes = useStyles();

  if (notFound) {
    return <Redirect to="/NijePronadjeno" />;
  }

  const title = !!realEstate ? `${realEstate.headline} - Rivalitas Nekretnine` : `${match.params.title} - Rivalitas Nekretnine`;

  const hasGallery = !!realEstate && !!realEstate.images && realEstate.images.length > 0;
  const similarQueries = createRealEstateSimilarSearchQueries(realEstate);

  const hasMap = !!realEstate && !!realEstate.longitude && !!realEstate.latitude;

  const handleCloseDialog = () => {
    setShowContactDialog(false);
    setContactName('');
    setContactData('');
    setContactMessage('');
  };

  async function sendContactMessage() {
    handleCloseDialog();

    const apiResult = await rivalitasApi.sendRealEstateContact({
      publicId: match.params.id,
      link: `https://www.rivalitas.hr/Nekretnine/${match.params.title}/${match.params.id}`,
      headline: realEstate.headline,
      name: contactName,
      contact: contactData,
      message: contactMessage,
      agentName: realEstate.agent ? realEstate.agent.name : null,
      agentEmail: realEstate.agent ? realEstate.agent.email : null,
      recaptchaToken
    });

    setContactSendSuccess(apiResult && apiResult.success);
    setShowContactConfirmation(true);
  };

  const handleContactNameChange = e => setContactName(e.target.value);
  const handleContactDataChange = e => setContactData(e.target.value);
  const handleContactMessageChange = e => setContactMessage(e.target.value);

  const handleCloseContactConfirmation = () => {
    setShowContactConfirmation(false);
  };

  // const verifyCallback = (recaptchaToken) => {
  //   setRecaptchaToken(recaptchaToken);
  // }

  return (
    <div>
      {/* <ReCaptcha
        sitekey={config.recaptchaKey}
        action="realEstate"
        verifyCallback={verifyCallback}
      /> */}

      <Helmet>
        <title>{title}</title>
      </Helmet>

      {loading &&
        <div className={classes.loading}>
          <LinearProgress />
        </div>
      }

      {!!realEstate &&
        <div className={classes.resultContent}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={hasGallery ? 7 : 12} lg={hasGallery ? 9 : 12}>
              <div className={classNames(classes.main, classes.mainRaised, classes.resultContent)}>
                <Toolbar className={classes.resultTitle}>
                  <Grid container alignItems="baseline">
                    <Grid item xs={12}>
                      <Typography variant="h6">{realEstate.headline}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} className={classes.subheaderContainer}>
                      <span className={classes.priceBig}>
                        {!!realEstate.price &&
                          <React.Fragment>
                            {`${formatMoney(realEstate.price)} €`}
                          </React.Fragment>
                        }
                        {!realEstate.price &&
                          'Cijena:'
                        }
                      </span>
                      <span className={classes.priceSmall}>
                        {(!!realEstate.price && realEstate.realEstateArea && realEstate.realEstateArea > 0) &&
                          <React.Fragment>
                            {`${formatMoney(realEstate.price / realEstate.realEstateArea)} €`} m<sup>2</sup>
                          </React.Fragment>
                        }
                        {!realEstate.price &&
                          'na upit:'
                        }
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} className={classes.similarQueriesContainer}>
                      {(!!similarQueries && similarQueries.length > 0) &&
                        <span>Pretraži slične nekretnine:&nbsp;</span>
                      }
                      {similarQueries.map((s, i) =>
                        <React.Fragment key={i}>
                          <Link className={classes.regularLink} to={s.link}>{s.label}</Link>
                          {(i < (similarQueries.length - 1)) &&
                            <span>&nbsp;/&nbsp;</span>
                          }
                        </React.Fragment>)
                      }
                    </Grid>
                  </Grid>
                </Toolbar>
                <hr />
                <div className={classes.realEstateContent}>
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <strong>Broj:</strong> {realEstate.publicId}
                      <br />
                      <strong>Datum:</strong> {moment(realEstate.date).format('DD.MM.YYYY')}
                      <br />
                      {realEstate.newbuilding &&
                        <Typography color="primary">Novogradnja</Typography>
                      }
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <div className={classes.agentContact}>
                        {(realEstate && realEstate.agent) &&
                          <React.Fragment>
                            {realEstate.agent.mobilePhone &&
                              <React.Fragment>
                                <MobilePhone /> {realEstate.agent.mobilePhone}
                                &nbsp;
                              </React.Fragment>
                            }
                            {realEstate.agent.phone &&
                              <React.Fragment>
                                <Phone /> {realEstate.agent.phone}
                                &nbsp;
                              </React.Fragment>
                            }
                          </React.Fragment>
                        }
                        <CustomButton
                          color="tumblr"
                          className={classes.button}
                          startIcon={<Mail />}
                          onClick={() => setShowContactDialog(true)}
                        >
                          Kontaktiraj agenta
                        </CustomButton>
                      </div>
                    </Grid>
                  </Grid>

                  <hr />

                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={7}>
                      {!!realEstate.header &&
                        <div dangerouslySetInnerHTML={{__html: realEstate.header}} />
                      }
                      {!!realEstate.details &&
                        <div dangerouslySetInnerHTML={{__html: realEstate.details}} />
                      }
                      {!!realEstate.footer &&
                        <div dangerouslySetInnerHTML={{__html: realEstate.footer}} />
                      }
                    </Grid>
                    {hasMap &&
                      <Grid item xs={12} sm={12} md={12} lg={5} className={classes.mapContainer}>
                        <div className={classes.mapFrame}>
                          <iframe
                            title="Karta"
                            width="100%"
                            height="450px"
                            src={`https://www.google.com/maps/embed/v1/search?key=AIzaSyD-F4slWBwr0WRjGHp-9neGCGUkQ1DlVII&q=${realEstate.latitude},${realEstate.longitude}&center=${realEstate.latitude},${realEstate.longitude}&zoom=18`}
                            frameBorder="0"
                            scrolling='no'
                            marginHeight='0'
                            marginWidth='0'
                          />
                        </div>
                      </Grid>
                    }
                  </Grid>

                  <hr />

                  <strong>Detalji nekretnine</strong>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell><strong>Naselje:</strong> {printString(realEstate.block)}</TableCell>
                        <TableCell><strong>Grad:</strong> {printString(realEstate.city)}</TableCell>
                        <TableCell><strong>Županija:</strong> {printString(realEstate.country)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><strong>Vrsta nekretnine:</strong> {printString(realEstate.realEstateType)}</TableCell>
                        <TableCell><strong>Vrsta ponude:</strong> {printString(realEstate.offerType)}</TableCell>
                        <TableCell><strong>Vrsta gradnje:</strong> {printString(realEstate.constructionType)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><strong>Površina:</strong> {printString(realEstate.realEstateArea)}{realEstate.realEstateArea && <span> m<sup>2</sup></span>}</TableCell>
                        <TableCell><strong>Površina okućnice:</strong> {printString(realEstate.realEstateInfieldArea)}{realEstate.realEstateInfieldArea && <span> m<sup>2</sup></span>}</TableCell>
                        <TableCell><strong>Broj soba:</strong> {printString(realEstate.roomNumber)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><strong>Broj kupaonica:</strong> {printString(realEstate.bathroomNumber)}</TableCell>
                        <TableCell>
                          <span>
                            {!!realEstate.price &&
                              <span>
                                <strong>Cijena: </strong>
                                {`${formatMoney(realEstate.price)} €`}
                              </span>
                            }
                            {(!!realEstate.price && realEstate.realEstateArea && realEstate.realEstateArea > 0) &&
                              <span>
                                / {`${formatMoney(realEstate.price / realEstate.realEstateArea)} €`} m<sup>2</sup>
                              </span>
                            }
                            {!realEstate.price &&
                              <span><strong>Cijena: </strong> na upit</span>
                            }
                          </span>
                        </TableCell>
                        <TableCell><strong>Godina gradnje:</strong> {printString(realEstate.yearOfConstruction)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Grid>
            {hasGallery &&
              <Grid item xs={12} sm={12} md={5} lg={3}>
                <div className={classNames(classes.main, classes.mainRaised, classes.resultContent)}>
                  <h4 className={classes.title}>Fotografije</h4>
                  <RealEstateImageGallery images={realEstate.images} title={realEstate.urlFriendlyHeadline} isMobile={isMobile} />
                </div>
              </Grid>
            }
          </Grid>
        </div>
      }

      <Dialog open={showContactDialog} onClose={handleCloseDialog} maxWidth="md" fullScreen={isMobile}>
        <DialogTitle id="form-dialog-title">Kotaktiraj agenta</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Molimo unesite Vaš kontakt i poruku. Javiti ćemo Vam se povratno u najkraćem roku.<br />
            {(realEstate && realEstate.agent) &&
              <div>
                <hr />
                <List>
                  {realEstate.agent.mobilePhone &&
                    <ListItem>
                      <ListItemIcon>
                        <MobilePhone />
                      </ListItemIcon>
                      <ListItemText>
                        {realEstate.agent.mobilePhone}
                      </ListItemText>
                    </ListItem>
                  }
                  {realEstate.agent.phone &&
                    <ListItem>
                      <ListItemIcon>
                        <Phone />
                      </ListItemIcon>
                      <ListItemText>
                        {realEstate.agent.phone}
                      </ListItemText>
                    </ListItem>
                  }
                </List>
              </div>
            }
          </DialogContentText>
          <hr />
          <TextField
            autoFocus
            margin="dense"
            label="Vaše ime"
            fullWidth
            value={contactName}
            onChange={handleContactNameChange}
          />
          <TextField
            margin="dense"
            label="Kontakt (Email ili telefon) *"
            fullWidth
            value={contactData}
            onChange={handleContactDataChange}
            error={!contactData}
            helperText={!contactData ? 'Molimo unesite Vaš kontakt kako bi Vas mogli kontaktirati' : null}
          />
          <TextField
            margin="dense"
            label="Vaša poruka"
            fullWidth
            multiline
            rows="4"
            value={contactMessage}
            onChange={handleContactMessageChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Odustani
          </Button>
          <Button onClick={sendContactMessage} color="primary" disabled={!contactData} variant="contained">
            Pošalji
          </Button>
        </DialogActions>
        <div>
          <TermsAndConditionsNote withMargin />
          {/* <RecaptchaNotes /> */}
        </div>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showContactConfirmation}
        autoHideDuration={6000}
        onClose={handleCloseContactConfirmation}
      >
        <SnackbarContentWrapper
          variant={contactSendSuccess ? 'success' : 'error'}
          className={classes.margin}
          message={contactSendSuccess ? 'Vaša poruka je poslana' : 'Greška: molimo pokušajte ponovo'}
          onClose={handleCloseContactConfirmation}
        />

      </Snackbar>
    </div>
  );
}

export default withRouter(RealEstate);
