import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Arrow from "@material-ui/icons/ArrowForward";
import Hidden from "@material-ui/core/Hidden";

import styles from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import noImage from "assets/img/realEstate.jpg";

import config from '../../config';
import { formatMoney } from '../../util';

const useStyles = makeStyles(theme => {
  return {
    ...styles,
    priceBig: {
      fontSize: '1.9em',
      color: theme.palette.primary.dark
    },
    priceSmall: {
      marginLeft: '10px',
      fontSize: '1.2em',
      color: theme.palette.grey['700']
    },
    realEstateInfoList: {
      width: '100%',
      margin: 0,
      padding: 0,
      display: 'table',
      background: '#fcfcfc',
      borderTop: '1px solid #e0eded',
      listStyle: 'none',
    },
    realEstateInfoListItem: {
      display: 'table-cell',
      padding: '10px 15px',
      verticalAlign: 'middle',
      borderRight: '1px solid #e0eded',
      '&:last-child': {
        borderRight: 'none',
      }
    },
    customizedCard: {
      ...styles.card,
      marginTop: 0,
      border: '1px solid #e0eded'
    },
    cardContent: {
      marginLeft: '15px',
      marginRight: '15px'
    },
    realEstateContent: {
      paddingLeft: '15px'
    }
  };
});

const limitTextLength = text => text && text.length <= 500 ? text : `${text.substring(0, 500)}...`;

const getRealEstateThumbnail = r => {
  if (!r || !r.image) {
    return noImage;
  }

  return `${config.serverUrl}Fotografije/${r.urlFriendlyHeadline}/${r.image.thumbnail}`;
};

const thumbStyle = {
  // height: '150px',
  // width: '180px',
  height: '90%',
  width: '100%',
  objectFit: 'cover'
}

function formatBlockAndCity(city, block) {
  return [block, city].filter(x => !!x).join(', ');
}

function formatBlockAndCityLink(city, block) {
  return encodeURI([block, city].filter(x => !!x).join(', '));
}

function RealEstatesListView({ realEstates }) {
  const classes = useStyles();
  
  return (
    <div className="cd-section">
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12}>
              {realEstates.map((r, i) => (
                <React.Fragment key={r.publicId} >
                  <Card plain blog className={classes.customizedCard}>
                    <GridContainer className={classes.cardContent}>
                      <GridItem xs={12}>
                        <h3 className={classes.cardTitle}>
                          <Link to={`/Nekretnine/${r.urlFriendlyHeadline}/${r.publicId}`}>
                            {r.headline}
                          </Link>
                        </h3>
                      </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.cardContent}>
                      <GridItem xs={12} sm={4} md={3}>
                        <CardHeader image plain>
                          <Link to={`/Nekretnine/${r.urlFriendlyHeadline}/${r.publicId}`}>
                            <img src={getRealEstateThumbnail(r)} alt={r.headline} style={thumbStyle} />
                          </Link>
                          <div
                            className={classes.coloredShadow}
                            style={{
                              backgroundImage: `url(${getRealEstateThumbnail(r)})`,
                              opacity: "1"
                            }}
                          />
                        </CardHeader>
                      </GridItem>
                      <GridItem xs={12} sm={8} md={9}>
                        <Info>
                          <h6 className={classes.cardCategory}>{r.realEstateType}</h6>
                        </Info>
                        
                        <p className={classes.description1}>
                          {limitTextLength(r.details)}
                          <Button
                            component={Link}
                            to={`/Nekretnine/${r.urlFriendlyHeadline}/${r.publicId}`}
                            color="primary"
                            className={classes.button}
                            endIcon={<Arrow />}
                          >
                            Detaljnije
                          </Button>
                        </p>
                        <p className={classes.author}>
                          <span className={classes.priceBig}>
                            {!!r.price &&
                              <React.Fragment>
                                {`${formatMoney(r.price)} €`}
                              </React.Fragment>
                            }
                            {!r.price &&
                              'Cijena:'
                            }
                          </span>
                          <span className={classes.priceSmall}>
                            {(!!r.price && r.realEstateArea && r.realEstateArea > 0) &&
                              <React.Fragment>
                                {`${formatMoney(r.price / r.realEstateArea)} €`} m<sup>2</sup>
                              </React.Fragment>
                            }
                            {!r.price &&
                              'na upit:'
                            }
                          </span>
                        </p>
                      </GridItem>
                    </GridContainer>
                    <ul className={classes.realEstateInfoList}>
                      {(!!r.realEstateArea && r.realEstateArea > 0) &&
                        <li className={classes.realEstateInfoListItem}>
                          <i className="fa fa-building" />&nbsp;Površina: {formatMoney(r.realEstateArea)} m<sup>2</sup>
                        </li>
                      }
                      <Hidden only="xs">
                        {(!!r.roomNumber && r.roomNumber > 0) &&
                          <li className={classes.realEstateInfoListItem}><i className="fa fa-user" />&nbsp;Sobnost: {r.roomNumber}</li>
                        }
                      </Hidden>
                      <Hidden only="xs">
                        {!!r.country &&
                          <li className={classes.realEstateInfoListItem}><i className="fa fa-map-marker" />&nbsp;Županija: {r.country}</li>
                        }
                      </Hidden>
                      {(r.city || r.block) &&
                        <li className={classes.realEstateInfoListItem}>
                          <i className="fa fa-tags" />&nbsp;<Link to={`/Pretraga/Napredna?lq=${formatBlockAndCityLink(r.city, r.block)}`}>{formatBlockAndCity(r.city, r.block)}</Link>
                        </li>
                      }
                      <li className={classes.realEstateInfoListItem}><i className="fa fa-location-arrow" />&nbsp;Broj: {r.publicId}</li>
                    </ul>
                  </Card>
                </React.Fragment>
              ))}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}


RealEstatesListView.propTypes = {
  realEstates: PropTypes.array.isRequired
};

export default React.memo(RealEstatesListView);
