import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
// import { ReCaptcha } from 'react-recaptcha-v3';

// import config from '../config';
import rivalitasApi from '../rivalitasApi';

// import RecaptchaNotes from '../components/common/RecaptchaNote';
import TermsAndConditionsNote from '../components/common/TermsAndConditionsNote';

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import Uncheck from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import Facebook from "@material-ui/icons/Facebook";
import People from "@material-ui/icons/PeopleAltOutlined";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import MobilePhone from "@material-ui/icons/PhoneAndroid";
import QueryBuilder from "@material-ui/icons/QueryBuilder";

import InfoArea from "components/InfoArea/InfoArea.js";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

import TextField from '@material-ui/core/TextField';

import { green } from '@material-ui/core/colors';

const useSnackStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const useStyles = makeStyles(contactUsStyle);

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

function SnackbarContentWrapper(props) {
  const classes = useSnackStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'success']).isRequired,
};

function Contact() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();

  const [recaptchaToken/*, setRecaptchaToken*/] = useState(null);

  const [contactName, setContactName] = useState('');
  const [contactData, setContactData] = useState('');
  const [contactMessage, setContactMessage] = useState('');

  const [showContactConfirmation, setShowContactConfirmation] = React.useState(false);
  const [contactSendSuccess, setContactSendSuccess] = useState(false);

  const handleContactNameChange = e => setContactName(e.target.value);
  const handleContactDataChange = e => setContactData(e.target.value);
  const handleContactMessageChange = e => setContactMessage(e.target.value);

  const handleContactSent = () => {
    setContactName('');
    setContactData('');
    setContactMessage('');
  };

  async function sendContactMessage() {
    handleContactSent();

    const apiResult = await rivalitasApi.sendContact({
      name: contactName,
      contact: contactData,
      message: contactMessage,
      recaptchaToken
    });

    setContactSendSuccess(apiResult && apiResult.success);
    setShowContactConfirmation(true);
  };

  // const verifyCallback = (recaptchaToken) => {
  //   setRecaptchaToken(recaptchaToken);
  // }

  const handleCloseContactConfirmation = () => {
    setShowContactConfirmation(false);
  };

  return (
    <div>
      {/* <ReCaptcha
        sitekey={config.recaptchaKey}
        action="contact"
        verifyCallback={verifyCallback}
      /> */}
      <Helmet>
        <title>Rivalitas Nekretnine - Kontaktirajte nas</title>
        <meta name="description" content="Kontaktirajte nas. Rivalitas Nekretnine. Grahorova 11, 10000 Zagreb" />
        <meta name="keywords" content="upit za stan, upit za nekretninu, upit za kuću" />
      </Helmet>

      <div className={classes.bigMap}>
        <iframe
          title="Karta"
          width="100%"
          height="100%"
          src={`https://www.google.com/maps/embed/v1/search?key=AIzaSyD-F4slWBwr0WRjGHp-9neGCGUkQ1DlVII&q=${encodeURI('Rivalitas d.o.o.')}&zoom=16`}
          frameBorder="0"
          scrolling='no'
          marginHeight='0'
          marginWidth='0'
        />
      </div>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <br />
            <h2 className={classes.title}>Kontaktirajte nas</h2>
            <GridContainer>
              <GridItem md={6} sm={6} xs={12}>
                <p>Pošaljite nam poruku i odgovoriti ćemo Vam u najkraćem mogućem roku.</p>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Vaše ime"
                  fullWidth
                  value={contactName}
                  onChange={handleContactNameChange}
                />
                <TextField
                  margin="dense"
                  label="Kontakt (Email ili telefon) *"
                  fullWidth
                  value={contactData}
                  onChange={handleContactDataChange}
                  error={!contactData}
                  helperText={!contactData ? 'Molimo unesite Vaš kontakt kako bi Vas mogli kontaktirati' : null}
                />
                <TextField
                  margin="dense"
                  label="Vaša poruka"
                  fullWidth
                  multiline
                  rows="4"
                  value={contactMessage}
                  onChange={handleContactMessageChange}
                />
                <Button onClick={sendContactMessage} color="primary" disabled={!contactData} variant="contained">
                  Pošalji
                </Button>
                <TermsAndConditionsNote />
                {/* <RecaptchaNotes /> */}
              </GridItem>
              <GridItem md={6} sm={6} xs={12} className={classes.mlAuto}>
                <InfoArea
                  className={classes.info}
                  title="Naš ured"
                  description={
                    <p>
                      Grahorova 11, 10 000 Zagreb
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="primary"
                />
                <InfoArea
                  className={classes.info}
                  title="Tel"
                  description={
                    <p>
                      +385 1 3701 093
                    </p>
                  }
                  icon={Phone}
                  iconColor="primary"
                />
                <InfoArea
                  className={classes.info}
                  title="Mob"
                  description={
                    <p>
                     +385 91 4640 100, +385 91 4640 500
                    </p>
                  }
                  icon={MobilePhone}
                  iconColor="primary"
                />
                <InfoArea
                  className={classes.info}
                  title="Radno vrijeme"
                  description={
                    <React.Fragment>
                      <p>
                        <Check fontSize="small" color="primary" />Ponedjeljak - Petak: 8:30 to 16:30
                      </p>
                      <p>
                        <Uncheck fontSize="small" color="primary" />Subota: +385 91 4640 100, +385 91 4640 500
                      </p>
                      <p>
                        <Uncheck fontSize="small" color="primary" />Nedjelja: +385 91 4640 100, +385 91 4640 500
                      </p>
                    </React.Fragment>
                  }
                  icon={QueryBuilder}
                  iconColor="primary"
                />
                <InfoArea
                  className={classes.info}
                  title="Pratite nas"
                  description={
                    <React.Fragment>
                      <p>
                        <Facebook fontSize="small" color="primary" /> <a href="https://www.facebook.com/profile.php?id=100090570810208" target="_blank" rel="noopener noreferrer">Rivalitas Nekretnine Facebook</a>
                      </p>
                    </React.Fragment>
                  }
                  icon={People}
                  iconColor="primary"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <br />
      <br />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showContactConfirmation}
        autoHideDuration={6000}
        onClose={handleCloseContactConfirmation}
      >
        <SnackbarContentWrapper
          variant={contactSendSuccess ? 'success' : 'error'}
          className={classes.margin}
          message={contactSendSuccess ? 'Vaša poruka je poslana' : 'Greška: molimo pokušajte ponovo'}
          onClose={handleCloseContactConfirmation}
        />

      </Snackbar>
    </div>
  );
}

export default Contact;
