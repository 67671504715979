import React from 'react';

import SearchResult from '../implementation/SearchResult';


const searchParams = {
  publicId: null,
  location: null,
  realEstateType: null,
  offerType: null,
  price: {
    from: null,
    to: null
  },
  area: {
    from: null,
    to: null
  },
  roomNumber: {
    from: null,
    to: null
  },
  floor: {
    from: null,
    to: null
  },
  newbuilding: true,
  hasLift: false
};

function Novogradnje() {

  const title = 'Rivalitas Nekretnine - Novogradnja stanovi i kuće - Zagreb, Hrvatska';
  const description = 'Prodaja novih stanova i kuća - Zagreb - pronađite i odaberite jednosobni, dvosobni, trosobni ili višesobni stan na prodaju. Novogradnja, Zagreb.';
  const keywords = 'agencija, nekretnine, stanovi, kuće, novogradnje, najam, prodaja';

  return (
    <SearchResult queryParams={searchParams} title={title} description={description} keywords={keywords} />
  );
}

export default Novogradnje;
