import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Euro from '@material-ui/icons/EuroSymbol';
import Tooltip from '@material-ui/core/Tooltip';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Info from "components/Typography/Info.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import noImage from "assets/img/realEstate.jpg";

import config from '../../config';
import { formatMoney } from '../../util';

const useStyles = makeStyles(styles);

const limitTextLength = text => text && text.length <= 30 ? text : `${text.substring(0, 30)}...`;

const getRealEstateThumbnail = r => {
  if (!r || !r.image) {
    return noImage;
  }

  return `${config.serverUrl}Fotografije/${r.urlFriendlyHeadline}/${r.image.thumbnail}`;
};

const thumbStyle = {
  height: '150px',
  objectFit: 'cover'
}

function RealEstatesCardView({ realEstates }) {
  const classes = useStyles();
  
  return (
    <div className={classes.container}>
      <GridContainer>
        {realEstates.map(r => (
          <GridItem xs={12} sm={6} md={4} lg={3} key={r.publicId}>
            <Card blog>
              <CardHeader image>
                <Link to={`/Nekretnine/${r.urlFriendlyHeadline}/${r.publicId}`}>
                  <img src={getRealEstateThumbnail(r)} alt={r.headline} style={thumbStyle} />
                </Link>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${getRealEstateThumbnail(r)})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody>
                <Info>
                  <h6 className={classes.cardCategory}>{r.realEstateType}</h6>
                </Info>
                <h4 className={classes.cardTitle}>
                  <Tooltip title={r.headline} placement="top">
                    <Link to={`/Nekretnine/${r.urlFriendlyHeadline}/${r.publicId}`}>
                      {limitTextLength(r.headline)}
                    </Link>
                  </Tooltip>
                </h4>
              </CardBody>
              <CardFooter>
                <div className={classes.stats + " " + classes.author}>
                  <span>{r.offerType}</span>
                </div>
                <div className={classes.stats + " " + classes.mlAuto}>
                  {!!r.price &&
                    <React.Fragment>
                      {formatMoney(r.price)}<Euro />
                    </React.Fragment>
                  }
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
}


RealEstatesCardView.propTypes = {
  realEstates: PropTypes.array.isRequired
};

export default React.memo(RealEstatesCardView);
