import Cookies from "js-cookie";
import PropTypes  from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Button from "@material-ui/core/Button";
import Snackbar from '@material-ui/core/Snackbar';

function isVisible(debug) {
  return Cookies.get('CookieConsent') === undefined || debug;
}

const CookieName = 'CookieConsent';
const CookieValue = true;
const CookieExpires = 365;

function CookieConsent({ debug }) {
  const [visible, setVisible] = useState(isVisible(debug));

  const onConfirm = () => {
    if (!debug) {
      Cookies.set(CookieName, CookieValue, { expires: CookieExpires });
    }
    setVisible(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={visible}
      onClose={onConfirm}
      message={<span>
        Koristimo <i>kolačiće</i> na ovoj stranici kako bi Vam pružili boju uslugu.<br />
        Korištenjem ovih mrežnih stranica pristajete na naše Uvjete.
        <br />
        <Button
          component={Link}
          to="/UvjetiKoristenja"
          color="primary"
        >
          Detaljnije
        </Button>
        <Button
          color="primary"
          onClick={onConfirm}
        >
          U redu
        </Button>
      </span>}
    />
  );
}

CookieConsent.defaultProps = {
  debug: false,
};
CookieConsent.propTypes = {
  debug: PropTypes.bool.isRequired,
};

export default CookieConsent;
