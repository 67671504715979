/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import routes from './config/routes';

import CookieConsent from "./components/common/CookieConsent";
import Header from "./components/Header/Header";
import HeaderLinks from "./components/Header/HeaderLinks";

import Footer from './components/common/Footer';

import ApplicationContext from './ApplicationContext';

import rivalitasApi from './rivalitasApi';

const pageContentStyle = {
  minHeight: '600px'
};

function Main(props) {
  const [registers, setRegisters] = useState({
    offerTypes: [],
    realEstateTypes: [],
  });

  async function loadData() {
    const registers = await rivalitasApi.getRegisters();
    if (registers) {
      setRegisters(registers);
    }
  }
  useEffect(() =>{
    loadData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  return (
    <ApplicationContext.Provider value={{
      registers
    }}>
      <div>
        <Header
          brand="Rivalitas Nekretnine"
          links={<HeaderLinks dropdownHoverColor="dark" location={props.location} />}
          fixed
          color="white"
        />

        <div style={pageContentStyle}>
          {routes}
        </div>

        <Footer />

        <CookieConsent />

      </div>
    </ApplicationContext.Provider>
  );
}

export default withRouter(Main);
