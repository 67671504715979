import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contactStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/contactStyle.js";

const useStyles = makeStyles(theme => {
  return {
    notFound: {
      backgroundColor: theme.palette.primary.main,
      height: '250px'
    },
    ...aboutUsStyle
  };
});

const useContactStyles = makeStyles(contactStyle);

function NotFound() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const contactClasses = useContactStyles();

  return (
    <div className="container">
      <Helmet>
        <title>Stranica nije pronađena - Rivalitas Nekretnine</title>
      </Helmet>

      <div className={classes.notFound} />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={contactClasses.aboutUs}>
            <GridContainer>
              <GridItem
                md={12}
                sm={12}
                className={classNames(contactClasses.mrAuto, contactClasses.mlAuto)}
              >
                <h2 className={classNames(contactClasses.title, contactClasses.textCenter)}>Tražena stranica nije pronađena</h2>
              </GridItem>
              <GridItem md={4} sm={12}>
                <h3>Tražili ste nekretninu?</h3>
                <p>
                  Nekretnina koju ste tražili je možda izbrisana ili privremeno uklonjena iz ponude. Možete pretražiti slične nekretnine <Link to="/Pretraga/Najnovije">ovdje</Link>.
                </p>

              </GridItem>
              <GridItem md={4} sm={12}>
                <h3>Htjeli ste nam poslati upit?</h3>
                <p>
                  Ukoliko imate bilo kakvih pitanja pošaljite nam upit. Odgovoriti ćemo Vam u najkraćem mogućem vremenu. Upit možete poslati <Link to="/Kontakt">ovdje</Link>.
                </p>
              </GridItem>
              <GridItem md={4} sm={12}>
                <h3>Htjeli ste nas kontaktirati?</h3>
                <p>
                  Tel: +385 1 3701 093<br />
                  Fax: +385 1 3772 833<br />
                  Mob: 091 4640 100, 091 4640 600<br />
                </p>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default NotFound;
