import config from '../config';

// https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-currency-string-in-javascript
// modified to remove last .00 -> don't need it
export function formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    let result = negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");

    if (result.endsWith( `${decimal}00` ) ) {
      result = result.substring(0, result.length - 3);
    }

    return result;
  } catch (e) {
    console.log(e)
  }
};

export function numberToString(n) {
  if (n === undefined || n === null || isNaN(n)) {
    return '';
  }

  return n.toString();
}

export function stringToPositiveNumber(s) {
  if (!s)
  {
    return null;
  }

  const parsed = parseInt(s);
  return !isNaN(parsed) && parsed >=0 ? parsed : null;
};

export function stringToNumber(s) {
  if (s === null || s === undefined) {
    return null;
  }

  const parsed = parseInt(s);
  return !isNaN(parsed) ? parsed : null;
};

export function createImageUrl(headline, imageName) {
  return `${config.serverUrl}Fotografije/${headline}/${imageName}`;
}


function createRealEstateSimilarSearchQueriesForRents(realEstate) {
  const result = [];

  if (!realEstate.city) {
    if ('stanovi' === realEstate.realEstateType.toLowerCase()) {
      result.push({ label: 'Stanovi za najam', link: '/Pretraga/Najam-Stanovi' });
    }
    if ('kuće' === realEstate.realEstateType.toLowerCase()) {
      result.push({ label: 'Kuće za najam', link: '/Pretraga/Najam-Kuce' });
    }
  } else {
    if ('zagreb' === realEstate.city.toLowerCase()) {
      result.push({ label: 'Nekretnine u Zagrebu za najam', link: '/Pretraga/Najam-Zagreb' });

      if ('stanovi' === realEstate.realEstateType.toLowerCase()) {
        result.push({ label: 'Stanovi za najam u Zagrebu', link: '/Pretraga/Najam-Stanovi-Zagreb' });
      }
      if ('kuće' === realEstate.realEstateType.toLowerCase()) {
        result.push({ label: 'Kuće za najam u Zagrebu', link: '/Pretraga/Najam-Kuce-Zagreb' });
      }
    } else {
      if ('stanovi' === realEstate.realEstateType.toLowerCase()) {
        result.push({ label: 'Stanovi za najam', link: '/Pretraga/Najam-Stanovi' });
      }
      if ('kuće' === realEstate.realEstateType.toLowerCase()) {
        result.push({ label: 'Kuće za najam', link: '/Pretraga/Najam-Kuce' });
      }
    }

  }

  result.push({ label: 'Nekretnine za najam', link: '/Pretraga/Najam' });

  return result;
}

function createRealEstateSimilarSearchQueriesForHouseSales(realEstate) {
  const result = [];

  result.push({ label: 'Kuće', link: '/Pretraga/Kuce' });
  return result;
}

function createRealEstateSimilarSearchQueriesForApartmentSales(realEstate) {
  const result = [];

  if (!!realEstate.block) {
    if (!!realEstate.city && ('zagreb' === realEstate.city.toLowerCase())) {
      let specialBlock = false;

      const block = realEstate.block.toLowerCase();

      if ('centar' === block) {
        result.push({ label: 'Prodaja stanova Centar', link: '/Pretraga/Stanovi-Zagreb-Centar' });
        specialBlock = true;
      }
      if ('jarun' === block) {
        result.push({ label: 'Prodaja stanova Jarun', link: '/Pretraga/Stanovi-Zagreb-Jarun' });
        specialBlock = true;
      }
      if ('črnomerec' === block) {
        result.push({ label: 'Prodaja stanova Črnomerec', link: '/Pretraga/Stanovi-Zagreb-Crnomerec' });
        specialBlock = true;
      }
      if ('vrbik' === block) {
        result.push({ label: 'Prodaja stanova Vrbik', link: '/Pretraga/Stanovi-Zagreb-Vrbik' });
        specialBlock = true;
      }
      if ('cvjetno naselje' === block) {
        result.push({ label: 'Prodaja stanova Cvjetno naselje', link: '/Pretraga/Stanovi-Zagreb-CvjetnoNaselje' });
        specialBlock = true;
      }
      if ('maksimir' === block) {
        result.push({ label: 'Prodaja stanova Maksimir', link: '/Pretraga/Stanovi-Zagreb-Maksimir' });
        specialBlock = true;
      }
      if ('dubrava' === block) {
        result.push({ label: 'Prodaja stanova Dubrava', link: '/Pretraga/Stanovi-Zagreb-Dubrava' });
        specialBlock = true;
      }
      if ('tresnjevka' === block) {
        result.push({ label: 'Prodaja stanova Trešnjevka', link: '/Pretraga/Stanovi-Zagreb-Tresenjvka' });
        specialBlock = true;
      }

      if (!specialBlock) {
        result.push({ label: `Prodaja stanova - ${realEstate.block}`, link: `/Pretraga/Napredna?lq=${encodeURI(realEstate.block + ', Zagreb')}&ot=${encodeURI(realEstate.offerType)}` });
      }
    }
  }

  if (!!realEstate.city && 'zagreb' === realEstate.city.toLowerCase()) {
    if (!!realEstate.roomNumber && (realEstate.roomNumber === 1)) {
      result.push({ label: 'Jednosobni stanovi u Zagrebu', link: '/Pretraga/Jednosobni-stanovi-Zagreb' });
    }
    if (!!realEstate.roomNumber && (realEstate.roomNumber === 2)) {
      result.push({ label: 'Dvosobni stanovi u Zagrebu', link: '/Pretraga/Dvosobni-stanovi-Zagreb' });
    }
    if (!!realEstate.roomNumber && (realEstate.roomNumber === 2)) {
      result.push({ label: 'Trosobni stanovi u Zagrebu', link: '/Pretraga/Trosobni-stanovi-Zagreb' });
    }
  } else {
    if (!!realEstate.roomNumber && (realEstate.roomNumber === 1)) {
      result.push({ label: 'Jednosobni stanovi', link: '/Pretraga/Jednosobni-Stanovi' });
    }
    if (!!realEstate.roomNumber && (realEstate.roomNumber === 2)) {
      result.push({ label: 'Dvosobni stanovi', link: '/Pretraga/Dvosobni-Stanovi' });
    }
    if (!!realEstate.roomNumber && (realEstate.roomNumber === 2)) {
      result.push({ label: 'Trosobni stanovi', link: '/Pretraga/Trosobni-Stanovi' });
    }
  }

  return result;
}

function createRealEstateSimilarSearchQueriesForSales(realEstate) {
  let result = [];

  if ('stanovi' === realEstate.realEstateType.toLowerCase()) {
    result = createRealEstateSimilarSearchQueriesForApartmentSales(realEstate);
  }
  else if ('kuće' === realEstate.realEstateType.toLowerCase()) {
    result = createRealEstateSimilarSearchQueriesForHouseSales(realEstate);
  }

  result.push({ label: 'Novogradnje', link: '/Pretraga/Novogradnje' });

  return result;
}

export function createRealEstateSimilarSearchQueries(realEstate) {
  if (!realEstate || !realEstate.offerType) {
    return [];
  }

  if ('najam' === realEstate.offerType.toLowerCase()) {
    return createRealEstateSimilarSearchQueriesForRents(realEstate);
  } else {
    return createRealEstateSimilarSearchQueriesForSales(realEstate);
  }
}
