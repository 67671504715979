import React from 'react';

import SearchResult from '../implementation/SearchResult';


const searchParams = {
  publicId: null,
  location: 'Zagreb',
  realEstateType: 'Stanovi',
  offerType: null,
  price: {
    from: null,
    to: null
  },
  area: {
    from: null,
    to: null
  },
  roomNumber: {
    from: 1,
    to: 1
  },
  floor: {
    from: null,
    to: null
  },
  newbuilding: false,
  hasLift: false
};

function JednosobniStanoviZagreb() {

  const title = 'Rivalitas Nekretnine - Jednosobni stanovi Zagreb - prodaja jednosobnih stanova u Zagrebu';
  const description = 'Tražite jednosobni stan u Zagrebu? Pregledajte našu ponudu jednosobnih stanova na području Zagreba i okolice.';
  const keywords = 'agencija, nekretnine, stanovi, kuće, novogradnje, najam, prodaja';

  return (
    <SearchResult queryParams={searchParams} title={title} description={description} keywords={keywords} />
  );
}

export default JednosobniStanoviZagreb;
